
import {defineComponent} from 'vue';
import {
  CmBaseComponent
} from 'cmt-vue-lib';
// import ReportsBase from "@/components/reports-designer/src/ReportsBase.vue";
import {IButton, IViewConfig, OpenMethodEnum} from './dynamic-views/types';

export default defineComponent({
  name: 'RoleHome',
  extends: CmBaseComponent,
  // components: {
  //   ReportsBase
  // },
  methods: {
    // openTaskItem() {
    //
    //   const viewConfig: IViewConfig = {
    //     component: 'ViewChild',
    //     actionBtns: [
    //       {
    //         title: '提交',
    //         action: 'submit',
    //         type: 'primary',
    //         disabled: false,
    //         loading: false,
    //         danger: false
    //       },
    //       {
    //         title: '取消',
    //         action: 'cancel',
    //         type: 'default',
    //         disabled: false,
    //         loading: false,
    //         danger: false
    //       }
    //     ] as IButton[],
    //     otherBtns: [
    //       {
    //         title: '导入',
    //         action: 'import',
    //         type: 'primary',
    //         disabled: false,
    //         loading: false,
    //         danger: false
    //       },
    //       {
    //         title: '导出',
    //         action: 'export',
    //         type: 'primary',
    //         disabled: true,
    //         loading: false,
    //         danger: false
    //       }
    //     ] as IButton[],
    //     params: {
    //       inside: 'hello word'
    //     }
    //   };
    //
    //   this.openDynamicView(viewConfig);
    //
    // },
    // openDynamicView(viewConfig: IViewConfig, method = OpenMethodEnum.标签页, title?: string) {
    //   switch (method) {
    //     case OpenMethodEnum.标签页:
    //       this.$cmSysSvr.openTab('ViewBase', title, viewConfig);
    //       break;
    //     case OpenMethodEnum.模态框:
    //       break;
    //   }
    // }

    openTaskItem(){
      this.$cmSysSvr.openTab('TCore', '核心');
    }
  }
});
