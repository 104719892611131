import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';

/**
 * 模型定义项
 *
 */
export class ModelDefineItem extends BaseDataEntity {
    id: string; // Id
    name: string; // 名称
    packageName: string; // 包名称
    index: number; // 索引
    variableName: string; // 变量名称
    variableType: string; // 变量类型（根据语言类型分类）
    extend: boolean; // 继承
    dbName: string; // 数据库字段名称（未定义时，默认不生成数据表字段）
    dbType: string; // 数据库字段类型
    dbLength: string; // 数据库字段长度
    dbNotEmpty: boolean; // 是否非空
    dbPrimaryKey: boolean; // 是否数据库主键
    dbQuery: boolean; // 是否生成查询参数
    dbOrderBy: boolean; // 是否生成排序参数
    dbCreateIndex: boolean; // 是否生成数据库索引
    dbDetail: boolean; // 是否生成基本查询参数
    dbKeyWord: boolean; // 是否生成关键字查询条件

    constructor(options: any = {}) {
        super(options);
        this.id = options?.id || '';
        this.name = options?.name || '';
        this.packageName = options?.packageName || '';
        this.index = options?.index || 0;
        this.variableName = options?.variableName || '';
        this.variableType = options?.variableType || '';
        this.extend = !!options.extend;
        this.dbName = options?.dbName || '';
        this.dbType = options?.dbType || '';
        this.dbLength = options?.dbLength || '0';
        this.dbNotEmpty = !!options?.dbNotEmpty;
        this.dbPrimaryKey = !!options?.dbPrimaryKey;
        this.dbQuery = !!options?.dbQuery;
        this.dbOrderBy = !!options?.dbOrderBy;
        this.dbCreateIndex = !!options?.dbCreateIndex;
        this.dbDetail = !!options?.dbDetail;
        this.dbKeyWord = !!options?.dbKeyWord;
    }
}

export class ModelDefineItemList extends DataList<ModelDefineItem> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ModelDefineItem(item));
            }
        }
    }
}
