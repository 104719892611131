import {request} from 'cmt-vue-lib';
import {CodeTemplateGroup, CodeTemplateGroupList} from '@/domains/code-template-group.domain';

/**
 * 代码模板组生成管理
 * <p>
 * description 代码模板组生成管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 13:59:12
 * @copyright (C) 2022 - 辰鸣科技
 */
export class CodeTemplateGroupController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'CodeTemplateGroupController.svc/';
    }

    // region 自动生成代码

    /**
     * 获取代码模板组列表
     *
     * @param token {string} 用户令牌
     * @param keyWord {string} 关键字
     * @param language {string} 语言
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getCodeTemplateGroupList(token: string ,keyWord: string ,language: string ,startIndex: number ,maxSize: number): Promise<CodeTemplateGroupList>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('language', language?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<CodeTemplateGroupList>(this.baseurl + 'getCodeTemplateGroupList', formData
        ).then(res => {
            return !!res ? new CodeTemplateGroupList(res) : null;
        });
    }

    /**
     * 修改代码模板组
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param name {string} 名称
     * @param version {string} 版本号
     * @param language {string} 语言
     * @param remark {string} 备注
     */
    public modifyCodeTemplateGroup(token: string ,id: string ,name: string ,version: string ,language: string ,remark: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('version', version?.toString() || '');
        formData.append('language', language?.toString() || '');
        formData.append('remark', remark?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyCodeTemplateGroup', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取代码模板组
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     */
    public getCodeTemplateGroup(token: string ,id: string): Promise<CodeTemplateGroup>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<CodeTemplateGroup>(this.baseurl + 'getCodeTemplateGroup', formData
        ).then(res => {
            return !!res ? new CodeTemplateGroup(res) : null;
        });
    }

    /**
     * 删除代码模板组
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param includeChildren {boolean} 是否有子集
     */
    public deleteCodeTemplateGroup(token: string ,id: string ,includeChildren: boolean): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('includeChildren', includeChildren?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteCodeTemplateGroup', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 添加代码模板组
     *
     * @param token {string} 用户令牌
     * @param name {string} 名称
     * @param version {string} 版本
     * @param language {string} 语言
     */
    public addCodeTemplateGroup(token: string ,name: string ,version: string ,language: string): Promise<string>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('version', version?.toString() || '');
        formData.append('language', language?.toString() || '');
        return request.post<string>(this.baseurl + 'addCodeTemplateGroup', formData
        ).then(res => {
            return res;
        });
    }

    // endregion

}
