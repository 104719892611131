import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';
import {Attachment} from 'cmt-vue-lib';

/**
 *
 * 代码生成模板
 * <p>
 * description 代码生成模板模型定义
 *
 * @author 向朗(xlang)
 * @date 2022-04-29 11:13:22
 * @copyright (C) 2022 - 辰鸣科技
 */
export class CodeTemplate extends BaseDataEntity {

    /**
     * 模板Id
     */
    id: string;
    /**
     * 模板库Id
     */
    templateGroupId: string;
    /**
     * 模板名称
     */
    name: string;
    /**
     * 模型类型（EModelDefineTypeEnum）
     */
    modelType: number;
    /**
     * 模板版本
     */
    version: string;
    /**
     * 生成文件名称前缀
     */
    prefix: string;
    /**
     * 生成文件名称后缀
     */
    suffix: string;
    /**
     * 生成文件扩展格式
     */
    extension: string;
    /**
     * 生成文件编码方式
     */
    encoding: string;
    /**
     * 模板状态（true:启动，false:禁用）
     */
    status: boolean;
    /**
     * 模板类型
     */
    templateType: string;
    /**
     * 模板内容
     */
    templateContent: Attachment;
    /**
     * 模板内容 - 用来做表单验证
     */
    content: File;

    constructor(options: any = {}) {
        super(options);
        this.id = options?.id || '';
        this.templateGroupId = options?.templateGroupId || '';
        this.name = options?.name || '';
        this.modelType = options?.modelType || 0 || undefined;
        this.version = options?.version || '';
        this.prefix = options?.prefix || '';
        this.suffix = options?.suffix || '';
        this.extension = options?.extension || '' || undefined;
        this.encoding = options?.encoding || '';
        this.status = !!options?.status;
        this.templateType = options?.templateType || '' || undefined;
        this.templateContent = new Attachment(options.templateContent || {});
        this.content = options.content || null;
    }
}

/**
 *
 * 代码生成模板列表
 * <p>
 * description 代码生成模板模型定义
 *
 * @author 向朗(xlang)
 * @date 2022-04-29 11:13:22
 * @copyright (C) 2022 - 辰鸣科技
 */
export class CodeTemplateList extends DataList<CodeTemplate> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new CodeTemplate(item));
            }
        }
    }
}

