
import {defineComponent, inject, onMounted} from 'vue';
import {sysSvr, msgSvr} from "cmt-vue-lib";
import {IButton} from './types';

export default defineComponent({
  name: "ViewChild",
  props: [],
  setup() {
    // 可通过依赖注入 获取到视图配置
    const viewConfig = inject('ViewConfig', null);
    const sourceViewConfig = inject('SourceViewConfig', null);

    onMounted(() => {
      sysSvr.setTabTitle('动态视图');
      console.log(viewConfig, sourceViewConfig);
    });

    sysSvr.onReceive<IButton>((event, btn) => {
      switch (event) {
        case 'submit':
          msgSvr.info(JSON.stringify(btn));
          break;
        case 'cancel':
          msgSvr.info(JSON.stringify(btn));
          break;
        case 'import':
          msgSvr.info(JSON.stringify(btn));
          break;
        case 'export':
          msgSvr.info(JSON.stringify(btn));
          break;
      }
    });


    return {};
  }
});
