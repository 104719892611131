import {defineComponent} from 'vue';
import {RedoOutlined, SendOutlined} from '@ant-design/icons-vue';
import {Project} from '@/domains/project.domain';
import {CmBaseComponent} from 'cmt-vue-lib';
import ProjectGenerationCode
    from '@/views/project/project-detail/project-generation-code/project-generation-code.component';

export default defineComponent({
    name: 'ProjectDetail',
    title: '工程详情',
    single: false,
    extends: CmBaseComponent,
    components: {
        RedoOutlined,
        SendOutlined
    },
    props: ['props'],
    setup() {
        return {};
    },
    data() {
        return {
            project: new Project(this.props),
            activeTabKey: 'ProjectDetailIndex', // 当前激活的tab页key
            generationCodeRef: undefined as InstanceType<typeof ProjectGenerationCode> | undefined,
            codeTemplateOptions: [],
            selectCodeTempKeys: [],
        };

    },
    created() {
        this.cmSysSvr.onReceive((event: string, data: any) => {
            switch (event) {
                case 'page-mounted':
                    this.generationCodeRef = this.$refs.GenerationCodeRef;
                    break;
                case 'page-setSelectCodeTempKeys':
                    this.selectCodeTempKeys = data;
                    break;
                case 'page-getCodeTemplateOptions':
                    this.selectCodeTempKeys = [];
                    this.codeTemplateOptions = data;
                    break;
            }
        });
    },
    methods: {
        selectCodeTempChange(values, selectOptions) {
            if (this.generationCodeRef) {
                this.generationCodeRef.selectCodeTempChange(values, selectOptions);
                this.selectCodeTempKeys = selectOptions.map(item => item.value); // 防止切换tab 数据丢失
            }
        },
        deleteCodeTemplate(values, deleteOption) {
            if (this.generationCodeRef) {
                this.generationCodeRef.deleteCodeTemplate(values, deleteOption);
            }
        },
        refreshGenerationCode() {
            this.generationCodeRef.getModelDefineList();
            this.generationCodeRef.getCodeTemplateList();
        },
        onBlurSetRelation() {
            this.generationCodeRef.setModelDefineAndCodeTemplateRelation(this.selectCodeTempKeys);
        }
    }
});
