import { defineComponent, onMounted, ref } from 'vue';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CloseOutlined,
    CompressOutlined,
    CopyOutlined,
    DeleteOutlined,
    FileDoneOutlined,
    FileMarkdownOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    FormOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined,
    DatabaseOutlined
} from '@ant-design/icons-vue';
import { AntTreeNodeDropEvent, TreeDataItem } from 'ant-design-vue/lib/tree';
// import Sortable from 'sortablejs';
import { ModelDefineController } from '@/services/model-define.controller';
import { ProjectStructureMgeController } from '@/services/project-structure-mge.controller';
import { Project } from '@/domains/project.domain';
import { ProjectStructure, ProjectStructureList } from '@/domains/project-structure.domain';
import { ModelDefine, ModelDefineList } from '@/domains/model-define.domain';
import { ModelDefineItem } from '@/domains/model-define-item.domain';
import { InterfaceDefineItem, } from '@/domains/interface-define-item.domain';
import { InterfaceDefineParam, InterfaceDefineParamList } from '@/domains/interface-define.domain';
import { ModelDefineTypeEnum } from '@/enums/model-define-type.enum';
import { ProjectStructureEnum } from '@/enums/project-structure.enum';
import {
    CmBaseComponent,
    SysDictController,
    DictTypeEnum,
    DictValueList,
    UUID,
    isEmpty,
    Utils
} from 'cmt-vue-lib';
import ModalDatabase from './modal-database/index.vue';

export interface StructTreeNodeDataSource {
    projectStructure: ProjectStructure;
    editState: boolean;
}

export interface StructTreeNode extends TreeDataItem {
    id: string;
    parentId: string;
    children: any;
    dataSource: StructTreeNodeDataSource;
}

export interface MenuData {
    index: number;
    record: any;
}

export interface TableRowInterfaceDefineItem extends InterfaceDefineItem {
    key: string;
    editParams: boolean;
}

export default defineComponent({
    name: 'ProjectDetailStructure',
    title: '项目结构',
    components: {
        PlusOutlined,
        FormOutlined,
        DeleteOutlined,
        QuestionCircleOutlined,
        FolderOpenOutlined,
        FolderOutlined,
        FileDoneOutlined,
        FileMarkdownOutlined,
        SaveOutlined,
        CompressOutlined,
        CopyOutlined,
        ArrowUpOutlined,
        ArrowDownOutlined,
        CloseOutlined,
        VerticalAlignTopOutlined,
        VerticalAlignBottomOutlined,
        DatabaseOutlined,
        ModalDatabase
    },
    extends: CmBaseComponent,
    props: ['propProject'],
    setup() {

        const modelDefineRules = {
            name: [{ required: true, message: '请输入模型名称!', trigger: ['change', 'blur'] }],
            packageName: [{ required: false, message: '请输入包名!', trigger: ['change', 'blur'] }],
            clazzName: [{ required: false, message: '请输入类名称!', trigger: ['change', 'blur'] }],
            parentClazzName: [{ required: false, message: '请选择基类!', trigger: ['change', 'blur'] }],
            tableName: [{ required: false, message: '请输入数据表名称!', trigger: ['change', 'blur'] }],
            dbExtends: [{ required: false, message: '请选择数据继承!', trigger: ['change', 'blur'] }],
            description: [{ required: false, message: '请输入模型说明!', trigger: ['change', 'blur'] }],
        };


        const modelDefineItemModelColumns = [
            {
                title: '对象模型定义',
                children: [
                    {
                        title: "序号",
                        key: 'index',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '名称',
                        key: 'name',
                        dataIndex: 'name',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '变量名称',
                        key: 'variableName',
                        dataIndex: 'variableName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '变量类型',
                        key: 'variableType',
                        dataIndex: 'variableType',
                        align: 'center',
                        width: 300,
                    }
                ]
            },
        ];
        const modelDefineItemDbColumns = [
            {
                title: '数据库定义',
                children: [
                    {
                        title: '列名',
                        key: 'dbName',
                        dataIndex: 'dbName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '类型',
                        key: 'dbType',
                        dataIndex: 'dbType',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '长度',
                        key: 'dbLength',
                        dataIndex: 'dbLength',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '非空',
                        key: 'dbNotEmpty',
                        dataIndex: 'dbNotEmpty',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '主键',
                        key: 'dbPrimaryKey',
                        dataIndex: 'dbPrimaryKey',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '查询',
                        key: 'dbQuery',
                        dataIndex: 'dbQuery',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '排序',
                        key: 'dbOrderBy',
                        dataIndex: 'dbOrderBy',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '详情',
                        key: 'dbDetail',
                        dataIndex: 'dbDetail',
                        align: 'center',
                        width: 50,
                    },
                    {
                        title: '关键字',
                        key: 'dbKeyWord',
                        dataIndex: 'dbKeyWord',
                        align: 'center',
                        width: 60,
                    },
                    {
                        title: '索引',
                        key: 'dbCreateIndex',
                        dataIndex: 'dbCreateIndex',
                        align: 'center',
                        width: 50,
                    }
                ],
            },
        ];
        const interfaceDefineItemColumns = [
            {
                title: "序号",
                key: 'index',
                align: 'center',
                width: 50,
            },
            {
                title: '名称',
                key: 'name',
                dataIndex: 'name',
                align: 'center',
            },
            {
                title: '变量名称',
                key: 'variableName',
                dataIndex: 'variableName',
                align: 'center',
            },
            {
                title: '值类型',
                key: 'returnType',
                dataIndex: 'returnType',
                align: 'center',
            },
            {
                title: '发布',
                key: 'publish',
                dataIndex: 'publish',
                align: 'center',
                width: 50,
            },
            // {
            //     title: '参数',
            //     key: 'editParams',
            //     dataIndex: 'editParams',
            //     align: 'center',
            //     width: 50,
            // },
        ];
        const interfaceParamsColumns = [
            {
                title: "序号",
                key: 'index',
                align: 'center',
                width: 50,
            },
            {
                title: '名称',
                key: 'name',
                dataIndex: 'name',
                align: 'center',
            },
            {
                title: '变量名称',
                key: 'variableName',
                dataIndex: 'variableName',
                align: 'center',
            },
            {
                title: '变量类型',
                key: 'variableType',
                dataIndex: 'variableType',
                align: 'center',
            },
        ];

        const modalDatabaseRef = ref<InstanceType<typeof ModalDatabase>>();

        return {
            modelDefineRules,
            modelDefineItemModelColumns,
            modelDefineItemDbColumns,
            interfaceDefineItemColumns,
            interfaceParamsColumns,

            modalDatabaseRef
        };
    },
    data() {
        return {
            project: new Project(this.propProject),
            databaseFieldTypeDictList: new DictValueList(), // 字典 - xxx数据字段类型库
            variableFieldTypeDictList: new DictValueList(), // 字典 - 变量类型
            parentClassDictList: new DictValueList(), // 字典 - 基类数据字典
            interfaceReturnTypeList: new DictValueList(), // 字典 - 接口返回类型(值类型)
            // -------------------------------------------------------------------
            btnInterfaceLoading: false,
            btnDomainLoading: false,
            btnGroupLoading: false,
            // -------------------------------------------------------------------
            projectStructureMgeController: new ProjectStructureMgeController(),
            projectStructure: new ProjectStructure(),
            projectStructureEnum: ProjectStructureEnum,
            projectStructureList: new ProjectStructureList(),
            sysDictController: new SysDictController(),
            projectStructureTree: [] as StructTreeNode[],
            selectedTreeKeys: [], // 当前选中树节点对应的key

            // -------------------------------------------------------------------
            editableStart: false,
            editableCancel: false,
            editableEnd: false,
            // ====================================================================
            modelDefineTypeEnum: ModelDefineTypeEnum,
            projectModelDefineController: new ModelDefineController(),
            projectModelDefineLoadLoading: false,

            modelDefine: new ModelDefine(), // 这里面应该有一个属性来判断它是接口还是对象还是其它
            selectedTableRowKeys: [] as string[], // 表格行全选
            sortable: undefined, // 表格拖放对象
            modelDefineDataSource: [], // 表格数据源 - 作用这是个地址 方便对表格进行曾删改查(as ModelDefineItem[] | InterfaceDefineItem[])
            // -------------------------------------------------------------------
            menuData: {} as MenuData,
            menuVisible: false,
            interfaceParamMenuVisible: false,
            menuStyle: {
                position: "fixed",
                top: "0",
                left: "0",
                border: "1px solid #eee",
                zIndex: "999",
            },
            // -------------------------------------------------------------------
            // 编辑接口区域显示状态
            interfaceParamAreaShowState: false,
            // 右边接口参数对象（里面包含参数列表）
            activeInterfaceDefineItem: undefined as undefined | InterfaceDefineItem,


            modalDataBaseVisible: false
        };
    },
    watch: {
        // todo... 列表向下拉BUG 禁止拖放
        // 'modelDefine.modelType'() {
        //     // 当表格发生切换 释放掉之前的拖放对象
        //     this.selectedTableRowKeys = [];
        //     if (this.sortable) {
        //         try {
        //             this.sortable.destroy();
        //             this.sortable = null;
        //         } catch (e) {
        //             // todo... 选中的树节点被删除,会释放失败!!!
        //         }
        //     }
        //     // 安装新的拖放对象
        //     this.$nextTick(() => {
        //         if (this.selectedTreeKeys.length <= 0) {
        //             return;
        //         }
        //         const tbody = this.$refs.dragTableRef.$el.querySelector('.ant-table-content > table > tbody');
        //         if (tbody) {
        //             this.sortable = Sortable.create(tbody, {
        //                 handle: '.ant-table-row',
        //                 swap: true,
        //                 animation: 150,
        //                 ghostClass: 'blue-background-class',
        //                 onEnd: ({newIndex, oldIndex}) => {
        //
        //                     console.log(this.sortable);
        //
        //
        //                     let items = [];
        //                     // 这里要根据不同表格进行切换
        //                     switch (this.modelDefine.modelType) {
        //                         case ModelDefineTypeEnum.Domain: {
        //                             items = this.modelDefine.modelDefineItems.items;
        //                             break;
        //                         }
        //                         case ModelDefineTypeEnum.Interface: {
        //                             items = this.modelDefine.interfaceDefineItems.items;
        //                             break;
        //                         }
        //                     }
        //
        //                     const oldRow = items[oldIndex];
        //                     const newRow = items[newIndex];
        //
        //                     items[newIndex] = oldRow;
        //                     items[oldIndex] = newRow;
        //                     // items.splice(newIndex, 1, oldRow);
        //                     // items.splice(oldIndex, 1, newRow);
        //
        //                 }
        //             });
        //
        //
        //         }
        //     });
        // },
    },
    computed: {
        getModelDefineColumns() {
            let columns = [];
            switch (this.modelDefine.modelType) {
                case ModelDefineTypeEnum.Domain: {
                    if (this.modelDefine.generateDbModel) {
                        columns = [...this.modelDefineItemModelColumns, ...this.modelDefineItemDbColumns];
                    } else {
                        columns = [...this.modelDefineItemModelColumns];
                    }
                    break;
                }
                case ModelDefineTypeEnum.Interface: {
                    columns = this.interfaceDefineItemColumns;
                    break;
                }
            }
            return columns;
        },
        getModelDefineDataSource() {
            let items = [];
            switch (this.modelDefine.modelType) {
                case ModelDefineTypeEnum.Domain: {
                    items = this.modelDefine.modelDefineItems.items;
                    break;
                }
                case ModelDefineTypeEnum.Interface: {
                    items = this.modelDefine.interfaceDefineItems.items;
                    break;
                }
            }
            this.modelDefineDataSource = items.map(item => {
                const key = item.key || UUID();
                return Object.assign(item, {
                    key: key,
                    id: key,
                    editParams: !!item.editParams // 定义接口中需要用它来打开一个列表
                });
            });

            // console.log('modelDefineDataSource', this.modelDefineDataSource);

            // 全选复选 需要一个key 所以需要重新构造
            return this.modelDefineDataSource;
        }
    },
    created() {
        this.getProjectStructureListByProjectCode();
        // console.log('=======================================================');
        // console.log('name:', this.project.name);
        // console.log('databaseType:', this.project.databaseType);
        // console.log('language:', this.project.language);
        // console.log('=======================================================');

        // 获取xxx数据字段类型库字典(数据库字段)字典
        this.getDictListByDatabaseType();
        // 获取变量类型(根据开发语言)字典
        this.getDictListByLanguage();
        // 获取父类（基类）字典
        this.getDictListByParentClass();
        // 获取接口返回类型(值类型)字典
        this.getDictListByReturnType();

    },
    unmounted() {
        if (this.sortable) {
            this.sortable.destroy();
        }
    },
    methods: {
        getDictListByDatabaseType() {
            this.sysDictController.getDictValueList(this.token, this.project.databaseType, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.databaseFieldTypeDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByLanguage() {
            this.sysDictController.getDictValueList(this.token, this.project.language, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.variableFieldTypeDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByParentClass() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DEV_LANGUAGE_PARENT_CLASS_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.parentClassDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByReturnType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DEV_LANGUAGE_RETURN_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.interfaceReturnTypeList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        // ===============================================================================================================
        getCurrentTime() {
            const cTime = new Date().getTime().toString();
            return Number(cTime.substring(2, cTime.length - 2));
        },
        onProjectStructureTreeNodeDrop(info: AntTreeNodeDropEvent) {
            const targetObj = info.node; // info.node 指的是拖放到的目标对象
            const sourceObj = info.dragNode; // info.dragNode 指的是被拖放对象
            const dropPos = info.node.pos.split('-');
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
            if (dropPosition === -1) {
                return this.cmMsgSvr.warn(`[${info.dragNode.dataSource.projectStructure.name}]不能成为根节点`);
            }

            if (!info.dropToGap) {
                // info.dropToGap 不在同一级

                if (targetObj.dataSource.projectStructure.type !== this.projectStructureEnum[ProjectStructureEnum.Group]) {
                    return this.cmMsgSvr.warn(`此节点[${sourceObj.dataSource.projectStructure.name}]不能移动到非分组节点下`);
                } else if (sourceObj.dataSource.projectStructure.parentId === targetObj.dataSource.projectStructure.id) {
                    return this.cmMsgSvr.warn(`此节点[${sourceObj.dataSource.projectStructure.name}]已在节点[${targetObj.dataSource.projectStructure.name}]下`);
                }

                this.projectStructureMgeController.modifyProjectStructure(this.token,
                    sourceObj.id,
                    targetObj.id,
                    sourceObj.dataSource.projectStructure.name,
                    sourceObj.dataSource.projectStructure.description,
                    sourceObj.dataSource.projectStructure.modelDefineId,
                    this.getCurrentTime()
                ).then(() => {
                    this.cmMsgSvr.success(`移动节点[${sourceObj.dataSource.projectStructure.name}]到节点下[${targetObj.dataSource.projectStructure.name}]成功`);
                    this.getProjectStructureListByProjectCode();
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                });


            } else if ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1) {
                // 这种情况是 拖放到的目标对象 它有子节点且是展开的且被拖放对象从它后面进入就会触发
                // 经过前面的处理 这里只能是分组触发 所有不用判断逻辑

                this.projectStructureMgeController.modifyProjectStructure(this.token,
                    sourceObj.id,
                    targetObj.id,
                    sourceObj.dataSource.projectStructure.name,
                    sourceObj.dataSource.projectStructure.description,
                    sourceObj.dataSource.projectStructure.modelDefineId,
                    this.getCurrentTime()).then(() => {
                        this.cmMsgSvr.success(`移动节点[${sourceObj.dataSource.projectStructure.name}]到节点[${targetObj.dataSource.projectStructure.name}]末尾成功`);
                        this.getProjectStructureListByProjectCode();
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    });


            } else {
                // 移动交换
                this.cmLoadingState = true;
                this.projectStructureMgeController.modifyProjectStructure(this.token,
                    sourceObj.id,
                    targetObj.parentId,
                    sourceObj.dataSource.projectStructure.name,
                    sourceObj.dataSource.projectStructure.description,
                    sourceObj.dataSource.projectStructure.modelDefineId,
                    targetObj.dataSource.projectStructure.index).then(async () => {

                        await this.projectStructureMgeController.modifyProjectStructure(this.token,
                            targetObj.id,
                            sourceObj.parentId,
                            targetObj.dataSource.projectStructure.name,
                            targetObj.dataSource.projectStructure.description,
                            targetObj.dataSource.projectStructure.modelDefineId,
                            sourceObj.dataSource.projectStructure.index);
                        this.cmMsgSvr.success(`移动节点[${sourceObj.dataSource.projectStructure.name}]到节点[${targetObj.dataSource.projectStructure.name}]成功`);
                        this.getProjectStructureListByProjectCode();
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.cmLoadingState = false;
                    });
            }
        },
        onDeleteProjectStructureSubmit(node: StructTreeNodeDataSource, nodeChildren: StructTreeNode[]) {
            return new Promise((resolve, reject) => {
                // 删除的当前节点下面有子节点不允许删除
                if ((nodeChildren || []).length > 0) {
                    this.cmMsgSvr.warn(`此[${node.projectStructure.name}]节点下存在子节点`);
                    return resolve(false);
                }
                this.projectStructureMgeController.deleteProjectStructure(this.token, node.projectStructure.id, false).then(async () => {
                    // 删除了结构 还要删除对应模型
                    if (node.projectStructure.modelDefineId) {
                        await this.projectModelDefineController.deleteModelDefine(this.token, node.projectStructure.modelDefineId);
                    }
                    // 如果是删除当前选择的树节点 右边就会被重置
                    if (this.selectedTreeKeys[0] === node.projectStructure.id) {
                        this.modelDefine = new ModelDefine();
                        this.selectedTreeKeys = [];
                    }

                    this.cmMsgSvr.success(`此[${node.projectStructure.name}]节点删除成功`);
                    this.getProjectStructureListByProjectCode();

                    resolve(true);
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                    reject(false);
                });
            });
        },
        onNewProjectStructureNode(node: StructTreeNodeDataSource, type: number) {
            // todo... 频繁操作 此函数防抖处理
            // 只能在分组下面建立对象，接口...
            if (node.projectStructure.type !== this.projectStructureEnum[ProjectStructureEnum.Group]) {
                return this.cmMsgSvr.warn(`当前节点[${node.projectStructure.name}]不是分组`);
            }

            switch (type) {
                case ProjectStructureEnum.Interface: {
                    this.btnInterfaceLoading = true;
                    this.projectStructureMgeController.addProjectStructure(
                        this.token,
                        node.projectStructure.projectCode,
                        node.projectStructure.id,
                        `未命名 - 接口`,
                        '',
                        this.projectStructureEnum[type],
                        this.getCurrentTime()
                    ).then(async (res: ProjectStructure) => {
                        if (res) {

                            // 新增一个模型对象(初始化)
                            const modelDefine = await this.projectModelDefineController.addModelDefine(this.token,
                                res.projectCode,
                                '未命名 - 模型名称',
                                res.description,
                                ProjectStructureEnum[res.type],
                                '', '', '', '');
                            if (!modelDefine) {
                                throw Error('[addModelDefine]新增模型定义后返回对象依旧为NULL');
                            } else {
                                await this.projectStructureMgeController.modifyProjectStructure(this.token,
                                    res.id,
                                    res.parentId,
                                    res.name,
                                    res.description,
                                    modelDefine.id,
                                    res.index);
                                this.cmMsgSvr.success('新增接口成功');
                                this.getProjectStructureListByProjectCode();
                            }

                        }
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.btnInterfaceLoading = false;
                    });
                    break;
                }
                case ProjectStructureEnum.Domain: {
                    this.btnDomainLoading = true;
                    this.projectStructureMgeController.addProjectStructure(
                        this.token,
                        node.projectStructure.projectCode,
                        node.projectStructure.id,
                        `未命名 - 对象`,
                        this.projectStructure.description,
                        this.projectStructureEnum[type],
                        this.getCurrentTime()
                    ).then(async (res: ProjectStructure) => {
                        if (res) {
                            // 新增一个模型对象(初始化)
                            const modelDefine = await this.projectModelDefineController.addModelDefine(this.token,
                                res.projectCode,
                                '未命名 - 模型名称',
                                res.description,
                                ProjectStructureEnum[res.type],
                                '', '', '', '');
                            if (!modelDefine) {
                                throw Error('[addModelDefine]新增模型定义后返回对象依旧为NULL');
                            } else {
                                await this.projectStructureMgeController.modifyProjectStructure(this.token,
                                    res.id,
                                    res.parentId,
                                    res.name,
                                    res.description,
                                    modelDefine.id,
                                    res.index);
                                this.cmMsgSvr.success('新增对象成功');
                                this.getProjectStructureListByProjectCode();
                            }

                        }
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.btnDomainLoading = false;
                    });
                    break;
                }
                case ProjectStructureEnum.Group: {
                    this.btnGroupLoading = true;
                    this.projectStructureMgeController.addProjectStructure(
                        this.token,
                        node.projectStructure.projectCode,
                        node.projectStructure.id,
                        `未命名 - 分组`,
                        '',
                        this.projectStructureEnum[type],
                        this.getCurrentTime()
                    ).then((res: ProjectStructure) => {
                        if (res) {
                            this.cmMsgSvr.success('新增分组成功');
                            this.getProjectStructureListByProjectCode();
                        }
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.btnGroupLoading = false;
                    });
                    break;
                }
            }
        },
        onModifyProjectStructureNode(node: StructTreeNodeDataSource) {
            // 当为非分组弹出编辑抽屉
            switch (node.projectStructure.type) {
                case this.projectStructureEnum[ProjectStructureEnum.Group]:
                    // 触发当前节点名称编辑事件
                    this.onEditableStart(node);
                    break;
                case this.projectStructureEnum[ProjectStructureEnum.Interface]:
                    // 触发当前节点名称编辑事件
                    this.onEditableStart(node);
                    break;
                // todo... 2022-5-20 17：30
                case this.projectStructureEnum[ProjectStructureEnum.Domain]:
                    this.onEditableStart(node);
                    break;
            }
        },
        onCopyProjectStructureNode(node: StructTreeNodeDataSource) {
            console.log(node.projectStructure);
            this.projectStructureMgeController.copyProjectStructure(this.token, node.projectStructure.id, node.projectStructure.parentId).then(() => {
                this.getProjectStructureListByProjectCode();
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        arrayTransformToTree(array, idKey, parentIdKey, isRoot): StructTreeNode[] {
            if (!!!array) return [];
            const idsObj = {};
            const tree = [];
            array.forEach(item => idsObj[item[idKey]] = item);
            Object.values(idsObj).forEach(item => {
                const parentId = item[parentIdKey],
                    parent = idsObj[parentId];
                if (!isRoot(item, parent)) {
                    !!!parent.children && (parent.children = []);
                    const children = parent.children;
                    !children.includes(item) && children.push(item);
                } else {
                    tree.push(item);
                }
            });
            return tree;
        },
        projectStructureTransformToTreeNode(projectStructure: ProjectStructure) {
            const treeNode: StructTreeNode = {
                title: projectStructure.name, // 这里的title === ProjectStructure name
                key: projectStructure.id, // 这里的key === ProjectStructure id
                id: projectStructure.id,
                parentId: projectStructure.parentId,
                children: projectStructure.children,
                isLeaf: projectStructure.type !== this.projectStructureEnum[ProjectStructureEnum.Group],
                selectable: !(projectStructure.type === this.projectStructureEnum[ProjectStructureEnum.Group]), // 分组节点不能被选中
                dataSource: {
                    projectStructure: projectStructure,
                    editState: false, // 这个标识用于单独触发编辑事件
                },
            };
            return treeNode;
        },
        treeChildrenSort(datalist: StructTreeNode[]): StructTreeNode[] {
            datalist.sort((obj1: StructTreeNode, obj2: StructTreeNode) => {
                const val1 = Number(obj1.dataSource.projectStructure.index);
                const val2 = Number(obj2.dataSource.projectStructure.index);
                if (val1 < val2) {
                    return -1;
                } else if (val1 > val2) {
                    return 1;
                } else {
                    return 0;
                }
            }).forEach((a: StructTreeNode) => {
                if (a.children) {
                    this.treeChildrenSort(a.children);
                }
            });
            return datalist;
        },
        getProjectStructureListByProjectCode() {
            this.cmLoadingState = true;
            this.projectStructureMgeController.getProjectStructureListByProjectCode(this.token,
                this.propProject.code).then((res: ProjectStructureList) => {
                    if (res) {
                        this.projectStructureList = new ProjectStructureList(res);
                        // ======================================================================================

                        // todo... 格式化数组(理论上这里需要把对应的children填充进去) - 转换成树组件认识的字段结构(字段结构！！！)
                        const formatStructureList: StructTreeNode[] = res.map(item => {
                            return this.projectStructureTransformToTreeNode(item);
                        });
                        // todo... 转树结构后期可封装在list基类中
                        const datalist: StructTreeNode[] = this.arrayTransformToTree(formatStructureList, 'id', 'parentId', item => item.parentId === "");
                        // ======================================================================================

                        // 生成树结构后 进行children排序
                        this.projectStructureTree = this.treeChildrenSort(datalist);
                    }
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                }).finally(() => {
                    this.cmLoadingState = false;
                });
        },
        onEditableStart(node: StructTreeNodeDataSource) {

            // 问题（实时刷新Get后台数据）：
            // 1.不论是按ESC(onEditableCancel),回车(onEditableEnd),失去焦点(onEditableEnd)，最终都要走onEditableEnd方法
            // 2.回车后会执行两次onEditableEnd
            // 解决:
            // 1.需要三个变量来记录这一流程
            // 2.这样可以做到Esc是只走onEditableCancel方法，后面的onEditableEnd方法不走。
            // 3.回车只走一次onEditableEnd方法。
            this.editableStart = true;
            this.editableCancel = false;
            this.editableEnd = false;

            // 节点搜索与编辑不能共存
            this.cmKeyWord = '';
            this.$nextTick(() => {
                // 当前选中的节点进入编辑状态
                if (!node.editState) {
                    node.editState = true;
                }
                // 触发数据更新
                this.projectStructureTree = [...this.projectStructureTree];
            });
        },
        onEditableCancel(oldName: string, node: StructTreeNodeDataSource) {
            this.editableCancel = true;

            node.editState = false;
            node.projectStructure.name = oldName;
            this.projectStructureTree = [...this.projectStructureTree];

            // 重新Get数据 自行还原
            // this.getProjectStructureListByProjectCode();

        },
        onEditableEnd(oldName: string, node: StructTreeNodeDataSource) {

            if (!this.editableEnd && !this.editableCancel) {

                this.editableEnd = true;
                this.editableStart = false;

                if (oldName === node.projectStructure.name) {
                    node.editState = false;
                    return this.projectStructureTree = [...this.projectStructureTree];
                } else if (isEmpty(node.projectStructure.name)) {
                    node.editState = false;
                    node.projectStructure.name = oldName;
                    this.projectStructureTree = [...this.projectStructureTree];
                    return this.cmMsgSvr.warn('新的节点名称不能为空');
                } else {
                    this.cmLoadingState = true;
                    this.projectStructureMgeController.modifyProjectStructure(this.token,
                        node.projectStructure.id,
                        node.projectStructure.parentId,
                        node.projectStructure.name,
                        node.projectStructure.description,
                        node.projectStructure.modelDefineId,
                        node.projectStructure.index).then(() => {
                            this.cmMsgSvr.success('修改节点名称成功!!!');
                            node.editState = false;
                        }).catch(err => {
                            this.cmMsgSvr.error(err.message);
                            node.editState = false;
                            node.projectStructure.name = oldName;
                        }).finally(() => {
                            this.projectStructureTree = [...this.projectStructureTree];
                            this.cmLoadingState = false;
                        });
                }
            }
        },
        // ===============================================================================================================
        onSelectProjectStructureTreeNode(selectedKeys: string[], e: { selected, nativeEvent, selectedNodes, node, event }) {

            // 这里先判断是不是编辑状态 如果是就不能让它选中
            if (this.editableStart) {
                return;
            }

            // 如果选择的是一个分组 就不让选中
            // if (e.node.dataSource.projectStructure.type === this.projectStructureEnum[ProjectStructureEnum.Group] || selectedKeys.length <= 0) {
            //     return;
            // }

            // 这个是选中状态 再次选中的时候不触发
            if (this.selectedTreeKeys.length > 0 && selectedKeys.length <= 0) {
                return;
            }

            // 有选中 且 新选中的和之前的不一样 那么就更新
            if (selectedKeys.length > 0 && this.selectedTreeKeys[0] !== selectedKeys[0]) {
                this.selectedTreeKeys = selectedKeys;
            }


            const projectStructure: ProjectStructure = e.node.dataSource.projectStructure;
            // 去获取节点对应的对象模板
            // todo... 重置一些状态
            this.selectedModelDefineItemKeys = [];
            this.projectModelDefineLoadLoading = true;
            this.projectModelDefineController.getModelDefine(this.token, projectStructure.modelDefineId).then(async res => {
                if (res) {
                    this.modelDefine = new ModelDefine(res);
                }

                // 切换到接口的时候要打开接口参数列表
                switch (projectStructure.type) {
                    case this.projectStructureEnum[ModelDefineTypeEnum.Domain]:
                        this.interfaceParamAreaShowState = false;
                        break;
                    case this.projectStructureEnum[ModelDefineTypeEnum.Interface]:
                        this.interfaceParamAreaShowState = true;
                        break;
                }
                this.activeInterfaceDefineItem = undefined;
                // this.interfaceParamAreaShowState = false;
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.projectModelDefineLoadLoading = false;
            });
        },
        createNewRowAnyObject() {
            // 创建一表格的一行数据 这是动态的any
            // todo... 有没有可能写成通用？ 后期优化...
            let targetItems = []; // todo... 这里理论上是引用对象，修改它会影响表格渲染
            let newObject: any = undefined;
            const modelDefine = this.modelDefine;
            switch (this.modelDefine.modelType) {
                case ModelDefineTypeEnum.Domain:
                    targetItems = modelDefine.modelDefineItems.items;
                    newObject = new ModelDefineItem();
                    break;
                case ModelDefineTypeEnum.Interface:
                    targetItems = modelDefine.interfaceDefineItems.items;
                    newObject = new InterfaceDefineItem();
                    break;
            }
            return {
                newObject,
                targetItems
            };
        },
        onNewRowModelDefineItem() {
            // 新增一行按钮触发
            const { targetItems, newObject } = this.createNewRowAnyObject();
            if (!newObject) {
                return;
            }
            newObject.index = this.getCurrentTime();
            targetItems.push(newObject);
        },
        onDeleteSelectRows() {
            return new Promise<boolean>((resolve) => {
                switch (this.modelDefine.modelType) {
                    case ModelDefineTypeEnum.Domain:
                        this.modelDefine.modelDefineItems.items = this.modelDefine.modelDefineItems.filter(item => !this.selectedTableRowKeys.includes(item.key));
                        break;
                    case ModelDefineTypeEnum.Interface:

                        if (this.selectedTableRowKeys.filter(key => key === this.activeInterfaceDefineItem?.id).length > 0) {
                            this.activeInterfaceDefineItem = undefined;
                        }

                        this.modelDefine.interfaceDefineItems.items = this.modelDefine.interfaceDefineItems.filter(item => !this.selectedTableRowKeys.includes(item.key));
                        break;
                }
                this.selectedTableRowKeys = [];
                this.cmMsgSvr.success('删除勾选的行成功');
                resolve(true);
            });
        },
        onCopySelectRows() {
            const { targetItems } = this.createNewRowAnyObject();
            const copyItems = targetItems
                .filter(item => this.selectedTableRowKeys.includes(item.key))
                .map(item => {
                    const key = UUID();
                    const newRow = Object.assign(Utils.deepClone<any>(item), {
                        key,
                        id: key,
                        index: item.index + 1,
                        editParams: false
                    });
                    if (newRow?.interfaceDefineParams) {
                        const arr = newRow.interfaceDefineParams?.items || newRow.interfaceDefineParams;
                        const newArr = arr.map(item => Object.assign(Utils.deepClone<any>(item), {
                            id: UUID(),
                            index: item.index + 1
                        }));
                        newRow.interfaceDefineParams = new InterfaceDefineParamList({ items: newArr });
                    }
                    return newRow;
                });
            targetItems.push(...copyItems);
            this.selectedTableRowKeys = [];
        },
        customTableRow(record, index) {
            return {
                onContextmenu: e => {
                    e.preventDefault();
                    this.menuData.record = record;
                    this.menuData.index = index;

                    this.menuVisible = true;
                    this.menuStyle.top = e.clientY + "px";
                    this.menuStyle.left = e.clientX + "px";


                    // 寻找cm-card-body 右键菜单位置自适应
                    let findEl: HTMLElement = undefined;
                    let node = e.target;
                    if (node?.parentNode) {
                        while (!findEl) {
                            if (node.nodeName === 'DIV' && node.className === 'cm-card-body') {
                                findEl = node;
                            } else {
                                node = node.parentNode;
                            }
                        }
                        const bodyRect = findEl.getBoundingClientRect();
                        const menuHeight = 217;
                        if (bodyRect.height + bodyRect.top < e.clientY + menuHeight) {
                            this.menuStyle.top = (bodyRect.height + bodyRect.top - menuHeight) + "px";
                            this.menuStyle.left = e.clientX + "px";
                        }
                    }

                    document.body.addEventListener("click", this.bodyClick);
                },
                onClick: e => {
                    // e.preventDefault();
                    // console.log(record);
                    if (record?.interfaceDefineParams) {
                        this.activeInterfaceDefineItem = record;
                    }
                    // 加上背景
                    let findCurrentTr: HTMLElement = undefined;
                    let node = e.target;
                    if (node?.parentNode) {
                        while (!findCurrentTr) {
                            if (node.nodeName === 'TR') {
                                findCurrentTr = node;
                            } else {
                                node = node.parentNode;
                            }
                        }
                        [...findCurrentTr.parentNode.children].forEach(item => {
                            if (item != findCurrentTr) {
                                item.classList.remove('select-table-row');
                            }
                        });
                        findCurrentTr.classList.toggle('select-table-row');
                    }
                }
            };
        },
        bodyClick() {
            this.menuVisible = false; // 关闭接口/对象模型表格右键菜单
            this.interfaceParamMenuVisible = false; // 关闭接口参数表格右键菜单
            this.menuData = {};
            document.body.removeEventListener("click", this.bodyClick);
        },
        onClickMenuItem({ key }) {
            // 处理右键菜单事件....
            const { index, record } = this.menuData;
            if (record instanceof InterfaceDefineParam) { // 这里走的是 接口参数表格右键菜单事件
                const interfaceDefineParams: InterfaceDefineParam[] = this.activeInterfaceDefineItem.interfaceDefineParams.items;
                const newObject = new InterfaceDefineParam({
                    index: this.getCurrentTime()
                });
                switch (key) {
                    case 'event-insert-before':
                        if (interfaceDefineParams.length === 1) {
                            newObject.index = interfaceDefineParams[index].index;
                            interfaceDefineParams[index].index = this.getCurrentTime();
                            interfaceDefineParams.unshift(newObject);
                        } else {
                            newObject.index = interfaceDefineParams[index].index;
                            for (let i = index; i < interfaceDefineParams.length; i++) {
                                if (i === interfaceDefineParams.length - 1) {
                                    interfaceDefineParams[i].index = this.getCurrentTime();
                                } else {
                                    interfaceDefineParams[i].index = interfaceDefineParams[i + 1].index;
                                }
                            }
                            interfaceDefineParams.splice(index, 0, newObject);
                        }
                        break;
                    case 'event-insert-after':
                        if (interfaceDefineParams.length === 1 || index === interfaceDefineParams.length - 1) {
                            newObject.index = this.getCurrentTime();
                        } else {
                            newObject.index = interfaceDefineParams[index + 1].index;
                            for (let i = index + 1; i < interfaceDefineParams.length; i++) {
                                if (i === interfaceDefineParams.length - 1) {
                                    interfaceDefineParams[i].index = this.getCurrentTime();
                                } else {
                                    interfaceDefineParams[i].index = interfaceDefineParams[i + 1].index;
                                }
                            }
                        }
                        interfaceDefineParams.splice(index + 1, 0, newObject);
                        break;
                    case 'event-remove-self':
                        for (let i = index; i < interfaceDefineParams.length; i++) {
                            if (i < interfaceDefineParams.length - 1) {
                                interfaceDefineParams[i + 1].index = interfaceDefineParams[i].index;
                            }
                        }
                        interfaceDefineParams.splice(index, 1);
                        break;
                    case 'event-move-before':
                        if (index === 0) {
                            return this.cmMsgSvr.warn('不能再上移动一行了');
                        } else {
                            const tmpItem = Utils.deepClone<any>(interfaceDefineParams[index]);
                            interfaceDefineParams[index] = Utils.deepClone<any>(interfaceDefineParams[index - 1]);
                            interfaceDefineParams[index].index = tmpItem.index;
                            tmpItem.index = interfaceDefineParams[index - 1].index;
                            interfaceDefineParams[index - 1] = tmpItem;
                            // interfaceDefineParams.splice(index, 1, ...interfaceDefineParams.splice(index - 1, 1, interfaceDefineParams[index]));
                        }
                        break;
                    case 'event-move-after':
                        if (index === interfaceDefineParams.length - 1) {
                            return this.cmMsgSvr.warn('不能再下移动一行了');
                        } else {
                            const tmpItem = Utils.deepClone<any>(interfaceDefineParams[index]);
                            interfaceDefineParams[index] = Utils.deepClone<any>(interfaceDefineParams[index + 1]);
                            interfaceDefineParams[index].index = tmpItem.index;
                            tmpItem.index = interfaceDefineParams[index + 1].index;
                            interfaceDefineParams[index + 1] = tmpItem;
                            // interfaceDefineParams.splice(index, 1, ...interfaceDefineParams.splice(index + 1, 1, interfaceDefineParams[index]));
                        }
                        break;
                }
            } else {
                const { targetItems, newObject } = this.createNewRowAnyObject();
                if (!newObject) {
                    return;
                }
                switch (key) {
                    case 'event-insert-before':
                        if (targetItems.length === 1) {
                            newObject.index = targetItems[index].index;
                            targetItems[index].index = this.getCurrentTime();
                            targetItems.unshift(newObject);
                        } else {
                            newObject.index = targetItems[index].index;
                            for (let i = index; i < targetItems.length; i++) {
                                if (i === targetItems.length - 1) {
                                    targetItems[i].index = this.getCurrentTime();
                                } else {
                                    targetItems[i].index = targetItems[i + 1].index;
                                }
                            }
                            targetItems.splice(index, 0, newObject);
                        }
                        break;
                    case 'event-insert-after':
                        if (targetItems.length === 1 || index === targetItems.length - 1) {
                            newObject.index = this.getCurrentTime();
                        } else {
                            newObject.index = targetItems[index + 1].index;
                            for (let i = index + 1; i < targetItems.length; i++) {
                                if (i === targetItems.length - 1) {
                                    targetItems[i].index = this.getCurrentTime();
                                } else {
                                    targetItems[i].index = targetItems[i + 1].index;
                                }
                            }
                        }
                        targetItems.splice(index + 1, 0, newObject);
                        break;
                    case 'event-remove-self':

                        if (this.activeInterfaceDefineItem?.id === targetItems[index].key) {
                            this.activeInterfaceDefineItem = undefined;
                        }

                        this.selectedTableRowKeys = this.selectedTableRowKeys.filter(key => key !== targetItems[index].key);
                        if (targetItems.length === 1 || index === targetItems.length - 1) {
                            targetItems.splice(index, 1);
                        } else {
                            for (let i = index; i < targetItems.length; i++) {
                                if (i < targetItems.length - 1) {
                                    targetItems[i + 1].index = targetItems[i].index;
                                }
                            }
                            targetItems.splice(index, 1);
                        }

                        break;
                    case 'event-move-before':
                        if (index === 0) {
                            return this.cmMsgSvr.warn('不能再上移动一行了');
                        } else {
                            const tmpItem = Utils.deepClone<any>(targetItems[index]);
                            targetItems[index] = Utils.deepClone<any>(targetItems[index - 1]);
                            targetItems[index].index = tmpItem.index;
                            tmpItem.index = targetItems[index - 1].index;
                            targetItems[index - 1] = tmpItem;
                            // targetItems.splice(index, 1, ...targetItems.splice(index - 1, 1, targetItems[index]));
                        }
                        break;
                    case 'event-move-after':
                        if (index === targetItems.length - 1) {
                            return this.cmMsgSvr.warn('不能再下移动一行了');
                        } else {
                            const tmpItem = Utils.deepClone<any>(targetItems[index]);
                            targetItems[index] = Utils.deepClone<any>(targetItems[index + 1]);
                            targetItems[index].index = tmpItem.index;
                            tmpItem.index = targetItems[index + 1].index;
                            targetItems[index + 1] = tmpItem;
                            // targetItems.splice(index, 1, ...targetItems.splice(index + 1, 1, targetItems[index]));
                        }
                        break;
                }
            }
        },
        // onEditInterfaceParams(row: TableRowInterfaceDefineItem) {
        //     // 如果说存在编辑状态
        //     if (this.modelDefineDataSource.some(item => item.editParams)) {
        //         // 点击到的就是编辑状态的
        //         if (row.editParams) {
        //             this.interfaceParamAreaShowState = false;
        //             row.editParams = false;
        //         } else {
        //             // 点击一个新的编辑 则发生改变
        //             this.modelDefineDataSource.forEach(item => {
        //                 item.editParams = item.key === row.key;
        //             });
        //         }
        //     } else {
        //         // 不存在一个编辑状态 则点击的那个进入编辑状态
        //         this.interfaceParamAreaShowState = true;
        //         row.editParams = true;
        //     }
        //
        //     // 找到当前打开的接口对象
        //     this.activeInterfaceDefineItem = this.modelDefineDataSource.find(item => item.editParams);
        // },
        onNewRowInterfaceParam() {
            if (this.activeInterfaceDefineItem) {
                this.activeInterfaceDefineItem.interfaceDefineParams.append(new InterfaceDefineParam({
                    index: this.getCurrentTime(),
                    id: UUID()
                }));
            } else {
                this.cmMsgSvr.warn('请先选择左侧接口');
            }
        },
        onModelDefineSubmit() {
            const { modelDefineRef } = this.$refs;
            if (!modelDefineRef) {
                this.cmMsgSvr.warn('没有选择对应的模板文件');
            } else {
                this.$refs.modelDefineRef.validate().then(() => {
                    this.modifyModelDefine().then(async () => {
                        switch (this.modelDefine.modelType) {
                            case ModelDefineTypeEnum.Domain:
                                await this.setModelDefineItems();
                                break;
                            case ModelDefineTypeEnum.Interface:
                                await this.setInterfaceDefineItems();
                                break;
                        }
                        this.cmMsgSvr.success('保存成功');
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        modifyModelDefine() {
            return this.projectModelDefineController.modifyModelDefine(this.token,
                this.modelDefine.id,
                this.modelDefine.name,
                this.modelDefine.description,
                this.modelDefine.modelType,
                this.modelDefine.packageName,
                this.modelDefine.clazzName,
                this.modelDefine.parentClazzName,
                this.modelDefine.tableName,
                this.modelDefine.generateDbModel,
                this.modelDefine.dbDefineUpperCase
            );
        },
        setInterfaceDefineItems() {
            return this.projectModelDefineController.setInterfaceDefineItems(this.token, this.modelDefine.id, this.modelDefine.interfaceDefineItems);
        },
        setModelDefineItems() {
            return this.projectModelDefineController.setModelDefineItems(this.token, this.modelDefine.id, this.modelDefine.modelDefineItems);
        },
        async handleModalDatabaseOk(list: ModelDefineList, structureIds: string[]) {
            const firstIndex = this.getCurrentTime();
            try {
                for (const index in list.items) {
                    const item = list.items[index];
                    const projectStructure: ProjectStructure = await this.projectStructureMgeController.addProjectStructure(
                        this.token,
                        this.project.code,
                        structureIds[0],
                        item.name,
                        item.description,
                        ProjectStructureEnum[ProjectStructureEnum['Domain']],
                        firstIndex + index
                    );
                    const modelDefine = await this.projectModelDefineController.addModelDefine(
                        this.token,
                        this.project.code,
                        item.name,
                        item.description,
                        ProjectStructureEnum['Domain'],
                        item.packageName,
                        item.clazzName,
                        item.parentClazzName,
                        item.tableName
                    );

                    await this.projectModelDefineController.setModelDefineItems(this.token, modelDefine.id, item.modelDefineItems);

                    await this.projectStructureMgeController.modifyProjectStructure(
                        this.token,
                        projectStructure.id,
                        projectStructure.parentId,
                        projectStructure.name,
                        projectStructure.description,
                        modelDefine.id,
                        projectStructure.index
                    );
                }
                this.cmMsgSvr.success('生成模型定义成功');
                this.getProjectStructureListByProjectCode();
            } catch (err) {
                this.cmMsgSvr.error(err.message);
            }
        },
        handleInputVariableNameBlur(row) {
            if (row instanceof ModelDefineItem && this.modelDefine.generateDbModel) {
                if (!row.dbName && row.variableName) {
                    if (this.modelDefine.dbDefineUpperCase) {
                        row.dbName = row.variableName.replace(/([A-Z])/g, "_$1").toUpperCase();
                    } else {
                        row.dbName = row.variableName.replace(/([A-Z])/g, "_$1").toLowerCase();
                    }
                }
            }
        },
        handleInputDbNameBlur() {
            this.handleDbNameToUpperCase();
        },
        handleDbNameToUpperCase() {
            if (!this.modelDefine.generateDbModel) return;
            if (this.modelDefine.tableName) {
                if (this.modelDefine.dbDefineUpperCase) {
                    this.modelDefine.tableName = this.modelDefine.tableName.toUpperCase();
                } else {
                    this.modelDefine.tableName = this.modelDefine.tableName.toLowerCase();
                }
            }
            this.getModelDefineDataSource.forEach(item => {
                if (item instanceof ModelDefineItem && item.dbName) {
                    if (this.modelDefine.dbDefineUpperCase) {
                        item.dbName = item.dbName.toUpperCase();
                    } else {
                        item.dbName = item.dbName.toLowerCase();
                    }
                }
            });
        },
        hadnleMoveUpRows() {
            const getOperateArray = () => {
                let operateArray = [];
                if (this.modelDefine.modelType === ModelDefineTypeEnum.Domain) {
                    operateArray = this.modelDefine.modelDefineItems.items;
                } else if (this.modelDefine.modelType === ModelDefineTypeEnum.Interface) {
                    operateArray = this.modelDefine.interfaceDefineItems.items;
                }
                return operateArray;
            };
            this.selectedTableRowKeys.toSorted((key1, key2) => {
                const operateArray = getOperateArray();
                const key1Index = operateArray.findIndex(item => item.key === key1);
                const key2Index = operateArray.findIndex(item => item.key === key2);
                return key1Index - key2Index;
            }).forEach(key => {
                const operateArray = getOperateArray();
                const findIndex = operateArray.findIndex(item => item.key === key);
                if (findIndex > 0) {
                    // 索引大于0才能移动
                    const tmpItem = Utils.deepClone<any>(operateArray[findIndex]);
                    operateArray[findIndex] = Utils.deepClone<any>(operateArray[findIndex - 1]);
                    operateArray[findIndex].index = tmpItem.index;
                    tmpItem.index = operateArray[findIndex - 1].index;
                    operateArray[findIndex - 1] = tmpItem;
                }
            });
        },
        hadnleMoveDownRows() {
            const getOperateArray = () => {
                let operateArray = [];
                if (this.modelDefine.modelType === ModelDefineTypeEnum.Domain) {
                    operateArray = this.modelDefine.modelDefineItems.items;
                } else if (this.modelDefine.modelType === ModelDefineTypeEnum.Interface) {
                    operateArray = this.modelDefine.interfaceDefineItems.items;
                }
                return operateArray;
            };
            this.selectedTableRowKeys.toSorted((key1, key2) => {
                const operateArray = getOperateArray();
                const key1Index = operateArray.findIndex(item => item.key === key1);
                const key2Index = operateArray.findIndex(item => item.key === key2);
                return key2Index - key1Index;
            }).forEach(key => {
                const operateArray = getOperateArray();
                const findIndex = operateArray.findIndex(item => item.key === key);
                if (findIndex < operateArray.length - 1) {
                    // 索引小于数组长度-1才能移动
                    const tmpItem = Utils.deepClone<any>(operateArray[findIndex]);
                    operateArray[findIndex] = Utils.deepClone<any>(operateArray[findIndex + 1]);
                    operateArray[findIndex].index = tmpItem.index;
                    tmpItem.index = operateArray[findIndex + 1].index;
                    operateArray[findIndex + 1] = tmpItem;
                }
            });
        }
    },
});
