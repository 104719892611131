import {BaseEntity, DataList} from 'cmt-vue-lib';

export class Listener extends BaseEntity {
    eventType: string;
    listenType: string;
    scriptFormat: string;
    scriptType: string;
    javaType: string;
    expression: string;
    proxyExpression: string;

    constructor(options: {
        eventType?: string;
        listenType?: string;
        scriptFormat?: string;
        scriptType?: string;
        javaType?: string;
        expression?: string;
        proxyExpression?: string;
    } = {}) {
        super();
        this.eventType = options.eventType || undefined;
        this.listenType = options.listenType || undefined;
        this.scriptFormat = options.scriptFormat || undefined;
        this.scriptType = options.scriptType || undefined;
        this.javaType = options.javaType || undefined;
        this.expression = options.expression || undefined;
        this.proxyExpression = options.proxyExpression || undefined;
    }
}

export class ListenerList extends DataList<Listener> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new Listener(item));
            }
        }
    }
}