import {BaseEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';

/**
 *
 * 模型模板关联
 * <p>
 * description 模型定义与代码生成模板关联
 *
 * @author 向朗(xlang)
 * @date 2022-04-26 18:45:37
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ModelDefineTemplateRelation extends BaseEntity {

    /**
     * ID
     */
    id: string;
    /**
     * 项目编号
     */
    projectCode: string;
    /**
     * 模型ID
     */
    modelDefineId: string;
    /**
     * 代码模板ID
     */
    codeTemplateIds: string[];

    constructor(options: any = {}) {
        super();
        this.id = options?.id || '';
        this.projectCode = options?.projectCode || '';
        this.modelDefineId = options?.modelDefineId || '';
        this.codeTemplateIds = options?.codeTemplateIds || [];
    }
}

/**
 * @project mysoft 框架项目
 *
 * 模型模板关联列表
 *
 * @author 向朗(xlang)
 * @date 2022-04-26 18:45:37
 * Copyright (C) 2022 - 辰鸣科技
 */
export class ModelDefineTemplateRelationList extends DataList<ModelDefineTemplateRelation> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ModelDefineTemplateRelation(item));
            }
        }
    }
}
