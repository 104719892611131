
import {defineComponent, inject, markRaw} from 'vue';
import {Utils} from 'cmt-vue-lib';
import {message} from 'ant-design-vue';
import {BaseInfo} from './base-info.domain';
import {isNCName} from '../../../utils';


export default defineComponent({
  name: "ElementBaseInfo",
  props: ['activeElement'],
  setup() {
    const size = inject('size', 'small');
    const designerInstance = inject('designerInstance', null);
    const modeling = inject('modeling', null);

    return {
      size,
      designerInstance,
      modeling
    };
  },
  data() {
    return {
      elementBaseInfo: new BaseInfo(),
    };
  },
  watch: {
    'activeElement.businessObject': {
      immediate: true,
      handler: function (val: any) {
        if (val) {
          this.$nextTick(() => {
            this.elementBaseInfo = new BaseInfo(markRaw(Utils.cloneByJson(this.activeElement.businessObject)));
          });
        }
      }
    }
  },
  methods: {
    onKeyUpdate() {
      const id = this.elementBaseInfo.id;
      if (!id) {
        return message.error('目标ID不能为空');
      } else if (!isNCName(id)) {
        return message.error('key 不满足 XML NCName 规则，所以不进行赋值');
      }
      this.updateBaseInfo('id');
    },
    onNameUpdate() {
      this.updateBaseInfo('name');
    },
    updateBaseInfo(key: string) {
      if (key === "id") {
        this.modeling.updateProperties(markRaw(this.activeElement), {
          id: this.elementBaseInfo.id,
          di: {id: `${this.elementBaseInfo.id}_di`}
        });
      } else {
        this.modeling.updateProperties(markRaw(this.activeElement), {name: this.elementBaseInfo.name});
      }
    }
  }
});
