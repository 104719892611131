import { request } from 'cmt-vue-lib';
import { CodeTemplate, CodeTemplateList } from '@/domains/code-template.domain';
import { DatabaseRepositoryList } from '@/domains/database-repository.domain';

/**
 * 数据库仓库管理Controller
 * <p>
 * description 数据库仓库管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 13:51:18
 * @copyright (C) 2022 - 辰鸣科技
 */
export class DatabaseRepositoryController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'DatabaseRepositoryController.svc/';
    }

    // region 自动生成代码

    /**
     * 获取数据库仓库列表
     *
     * @param token {string} 用户令牌
     * @param keyWord {string} 关键字
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getDatabaseRepositoryList(token: string, keyWord: string, startIndex: number, maxSize: number): Promise<DatabaseRepositoryList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<DatabaseRepositoryList>(this.baseurl + 'getDatabaseRepositoryList', formData).then(res => {
            return !!res ? new DatabaseRepositoryList(res) : null;
        });
    }

    // endregion

}
