import { BaseEntity } from 'cmt-vue-lib';
import { DataList } from 'cmt-vue-lib';

/**
 *
 * 数据仓库
 * <p>
 * description 数据仓库
 *
 * @author 向朗(xlang)
 * @date 2022-04-26 18:45:37
 * @copyright (C) 2022 - 辰鸣科技
 */
export class DatabaseRepository extends BaseEntity {

    /**
     * ID
     */
    id: string;
    /**
     * 名称
     */
    name: string;
    /**
     * 驱动ID
     */
    driverId: string;
    /**
     * 数据库URL
     */
    url: string;
    /**
     * 数据库URL
     */
    username: string;
    /**
    * 数据库URL
    */
    password: string;
    /**
     * 数据库URL
     */
    remark: string;

    constructor(options: any = {}) {
        super();
        this.id = options?.id;
        this.name = options?.name;
        this.driverId = options?.driverId;
        this.url = options?.url;
        this.username = options?.username;
        this.password = options?.password;
        this.remark = options?.remark;
    }
}

/**
 * @project mysoft 框架项目
 *
 * 模型模板关联列表
 *
 * @author 向朗(xlang)
 * @date 2022-04-26 18:45:37
 * Copyright (C) 2022 - 辰鸣科技
 */
export class DatabaseRepositoryList extends DataList<DatabaseRepository> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new DatabaseRepository(item));
            }
        }
    }
}
