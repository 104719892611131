
import { CmBaseComponent } from 'cmt-vue-lib';
import { defineComponent } from 'vue';
import { ModelDefineController } from '@/services/model-define.controller';
import ListDatabase from './list-database.vue';
import TreeSelectStructure from './tree-select-structure.vue';
import {
    SendOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
    name: 'ModalDatabase',
    extends: CmBaseComponent,
    props: ['project'],
    emits: ['ok'],
    components: {
        ListDatabase,
        TreeSelectStructure,
        SendOutlined
    },
    data() {
        return {
            visible: false,
            modelDefineController: new ModelDefineController(),
            generateModelDefineLoading: false,
            isColumnLowercase: false,
            isRemoveConnSym: false
        };
    },
    methods: {
        openModal() {
            this.visible = true;
        },
        closeModal() {
            this.visible = false;
        },
        handleOk() {
            const { listDatabaseRef, treeSelectStructureRef } = this.$refs;
            if (listDatabaseRef.selectedTableNames.length > 10) {
                return this.cmMsgSvr.error('选择的表名不能超过10个');
            }
            this.generateModelDefineLoading = true;
            this.modelDefineController.generateModelDefine(
                this.token,
                listDatabaseRef.selectDatabaseRepositoryId,
                listDatabaseRef.selectedTableNames,
                this.isColumnLowercase,
                this.isRemoveConnSym
            ).then(res => {
                if (res) {
                    this.$emit('ok', res, treeSelectStructureRef.selectedTreeKeys);
                    this.closeModal();
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.generateModelDefineLoading = false;
            });
        }
    }
});

