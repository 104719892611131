
import {defineComponent, markRaw} from 'vue';
import BpmnViewer, {Viewer, ElementRegistry, Modeling} from "bpmn-js/lib/Viewer";
import MoveCanvasModule from "diagram-js/lib/navigation/movecanvas";
import ModelingModule from 'bpmn-js/lib/features/modeling';
import DefaultEmptyXML from "./config/defaultEmpty";

export default defineComponent({
  name: "ProcessViewer",
  props: {
    processXml: String, // xml 字符串
    processId: String, // 流程 key 标识
    processName: String, // 流程 name 名字
  },
  data() {
    return {
      bpmnViewer: null as Viewer,
      elementRegistry: null as ElementRegistry,
      modeling: null as Modeling,
    };
  },
  mounted() {
    this.initBpmnViewer();
    this.$nextTick(() => {
      this.createNewDiagram(this.processXml);
    });
  },
  beforeUnmount() {
    if (this.bpmnViewer) {
      this.bpmnViewer.destroy();
    }
    this.bpmnViewer = null;
  },
  methods: {
    initBpmnViewer() {
      this.bpmnViewer = markRaw(new BpmnViewer({
        container: '.process-designer__canvas',
        keyboard: {bindTo: document},
        additionalModules: [
          ModelingModule,
          MoveCanvasModule
        ],
      }));
      this.elementRegistry = markRaw(this.bpmnViewer.get('elementRegistry'));
      this.modeling = markRaw(this.bpmnViewer.get('modeling'));
    },
    createNewDiagram(xml?: string) {
      const timeId = new Date().getTime();
      // id不能以数字开头，否则导入xml报错。
      const newId = this.processId || `Process_${timeId}`;
      const newName = this.processName || `业务流程_${timeId}`;
      const xmlString = xml || DefaultEmptyXML(newId, newName);

      this.bpmnViewer.importXML(xmlString).then(({warnings}) => {
        const canvas = this.bpmnViewer.get('canvas');
        canvas.zoom("fit-viewport", "auto");
        if (warnings && warnings.length) {
          warnings.forEach(warn => console.warn(warn));
        }

        this.setColor(this.elementRegistry.getAll());

      }).catch(err => {
        // console.error(err);
        this.$emit('error', err);
      });
    },
    setColor(all: any | Array<any>) {
      this.modeling.setColor(all, {
        stroke: 'green',
      });
    }
  }

});

