import {BaseEntity, DataList} from 'cmt-vue-lib';

/**
 *
 * 团队成员
 * <p>
 * description 团队成员模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:39:03
 * @copyright (C) 2022 - 辰鸣科技
 */
export class TeamMember extends BaseEntity {

    /**
     * ID
     */
    id: string;
    /**
     * 团队Id
     */
    teamId: string;
    /**
     * 成员Id
     */
    userId: string;
    /**
     * 备注
     */
    remark: string;

    constructor(options: {
        id?: string;
        teamId?: string;
        userId?: string;
        remark?: string;
    } = {}) {
        super();
        this.id = options?.id || '';
        this.teamId = options?.teamId || '';
        this.userId = options?.userId || '';
        this.remark = options?.remark || '';
    }
}

/**
 *
 * 团队成员列表
 * <p>
 * description 团队成员模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:39:03
 * @copyright (C) 2022 - 辰鸣科技
 */
export class TeamMemberList extends DataList<TeamMember> {
    constructor(options: any = {}) {
        super();
        if (options.items) {
            for (const item of options.items) {
                this.append(new TeamMember(item));
            }
        }
    }
}

