import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';

/**
 *
 * 流程模板
 * <p>
 * description 流程模板模型定义
 *
 * @author 超管理员(admin)
 * @date 2022-05-25 11:20:23
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProcessTemplate extends BaseDataEntity {

    /**
     * 模板Id
     */
    id: string;
    /**
     * 模板名称
     */
    name: string;
    /**
     * 流程分类
     */
    category: string;
    /**
     * Bpmn模型（XML）
     */
    bpmnModelXml: string;
    /**
     * 状态(true:可用,false:禁用)
     */
    status: boolean;

    constructor(options: {
        id?: string;
        name?: string;
        category?: string;
        bpmnModelXml?: string;
        status?: boolean;
    } = {}) {
        super(options);
        this.id = options?.id || '';
        this.name = options?.name || undefined;
        this.category = options?.category || undefined;
        this.bpmnModelXml = options?.bpmnModelXml || '';
        this.status = !!options?.status;
    }
}

/**
 *
 * 流程模板列表
 * <p>
 * description 流程模板模型定义
 *
 * @author 超管理员(admin)
 * @date 2022-05-25 11:20:23
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProcessTemplateList extends DataList<ProcessTemplate> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ProcessTemplate(item));
            }
        }
    }
}

