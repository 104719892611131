import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    autocomplete: "off",
    model: _ctx.elementBaseInfo
  }, {
    default: _withCtx(() => [
      (_ctx.elementBaseInfo?.$type==='bpmn:Process')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_a_form_item, {
              label: "流程标识",
              name: "id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.elementBaseInfo.id,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.elementBaseInfo.id) = $event)),
                  size: _ctx.size,
                  disabled: "",
                  onBlur: _ctx.onKeyUpdate
                }, null, 8, ["value", "size", "onBlur"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "流程名称",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.elementBaseInfo.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.elementBaseInfo.name) = $event)),
                  size: _ctx.size,
                  "allow-clear": "",
                  onBlur: _ctx.onNameUpdate
                }, null, 8, ["value", "size", "onBlur"])
              ]),
              _: 1
            })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_a_form_item, {
              label: "元素标识",
              name: "id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.elementBaseInfo.id,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.elementBaseInfo.id) = $event)),
                  size: _ctx.size,
                  "allow-clear": "",
                  onBlur: _ctx.onKeyUpdate
                }, null, 8, ["value", "size", "onBlur"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "元素名称",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.elementBaseInfo.name,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.elementBaseInfo.name) = $event)),
                  size: _ctx.size,
                  "allow-clear": "",
                  onBlur: _ctx.onNameUpdate
                }, null, 8, ["value", "size", "onBlur"])
              ]),
              _: 1
            })
          ], 64))
    ]),
    _: 1
  }, 8, ["model"]))
}