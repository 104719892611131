import { defineComponent } from 'vue';
import { CopyOutlined, RedoOutlined } from '@ant-design/icons-vue';

import { ModelDefineController } from '@/services/model-define.controller';
import { CodeTemplateController } from '@/services/code-template.controller';
import { CodeGenerateController } from '@/services/code-generate.controller';
import { ModelDefineTemplateRelationController } from '@/services/model-define-template-relation.controller';
import { Project } from '@/domains/project.domain';
import { ModelDefineTemplateRelation } from '@/domains/model-define-template-relation.domain';
import { ModelDefine, ModelDefineList } from '@/domains/model-define.domain';
import { CodeTemplate, CodeTemplateList } from '@/domains/code-template.domain';
import { ModelDefineTypeEnum } from '@/enums/model-define-type.enum';
import { ProjectStructureEnum } from '@/enums/project-structure.enum';

import useClipboard from 'vue-clipboard3';
import { CmBaseComponent } from 'cmt-vue-lib';

// import 'highlight.js/lib/common'; // 全部样式
import 'highlight.js/styles/vs.css';
import highlightJs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import java from 'highlight.js/lib/languages/java';
import xml from 'highlight.js/lib/languages/xml';
import less from 'highlight.js/lib/languages/less';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';
import csharp from 'highlight.js/lib/languages/csharp';
import cpp from 'highlight.js/lib/languages/cpp';
import c from 'highlight.js/lib/languages/c';

import highlightJsVuePlugin from "@highlightjs/vue-plugin";
// 在window中注册一个hljs 包含生成代码行号方法
import '@/assets/js/highlightjs-line-numbers.js';

highlightJs.registerLanguage('javascript', javascript);
highlightJs.registerLanguage('json', json);
highlightJs.registerLanguage('java', java);
highlightJs.registerLanguage('xml', xml);
highlightJs.registerLanguage('less', less);
highlightJs.registerLanguage('css', css);
highlightJs.registerLanguage('csharp', csharp);
highlightJs.registerLanguage('cpp', cpp);
highlightJs.registerLanguage('c', c);


export default defineComponent({
    name: 'ProjectGenerationCode',
    title: '代码生成',
    extends: CmBaseComponent,
    components: {
        CopyOutlined,
        RedoOutlined,
        HighlightJs: highlightJsVuePlugin.component
    },
    props: ['propProject'],
    setup() {
        const { toClipboard } = useClipboard();
        return {
            toClipboard
        };
    },
    data() {
        return {
            modelType: -1,

            project: new Project(this.propProject),
            projectStructureEnum: ProjectStructureEnum,
            loadCodeLoading: false,
            loadRelationLoading: false,
            modelDefineController: new ModelDefineController(),
            modelDefineTemplateRelationController: new ModelDefineTemplateRelationController(),
            codeTemplateController: new CodeTemplateController(),
            codeGenerateController: new CodeGenerateController(),

            generateCode: '',
            modelDefineList: new ModelDefineList(),
            codeTemplateList: new CodeTemplateList(),

            filterCodeTemplateList: [] as CodeTemplate[], // select组件显示用
            selectCodeTempList: [] as CodeTemplate[], // 渲染tab页需要的数据源
            activeTabByCodeTemplateId: '', // 当前选择的tab
            menuSelectKeys: [] as string[], // 当前选中的菜单

            selectModelDefine: new ModelDefine(), // 当前选择的模型定义对象
        };
    },
    watch: {
        generateCode() {
            this.$nextTick(() => {
                // window?.hljs?.initLineNumbersOnLoad();
            });
        },
        'selectModelDefine.modelType'() {
            // 过滤数据
            switch (this.selectModelDefine.modelType) {
                case ModelDefineTypeEnum.Domain:
                    this.filterCodeTemplateList = this.codeTemplateList.filter(item => item.modelType === ModelDefineTypeEnum.Domain);
                    break;
                case ModelDefineTypeEnum.Interface:
                    this.filterCodeTemplateList = this.codeTemplateList.filter(item => item.modelType === ModelDefineTypeEnum.Interface);
                    break;
            }
            // 通知....
            this.cmSysSvr.sendMessage('page-getCodeTemplateOptions', this.filterCodeTemplateList.map(item => {
                return {
                    value: item.id,
                    label: item.name + ' ' + item.version,
                    dataSource: item
                };
            }));
        }
    },
    created() {
        this.getCodeTemplateList();
    },
    mounted() {
        this.$nextTick(() => {
            this.cmSysSvr.sendMessage('page-mounted', {});
        });
    },
    methods: {
        // 获取模型定义
        getModelDefineList() {
            return this.modelDefineController.getModelDefineList(this.token, this.modelType,this.cmKeyWord, this.project.code, 0, -1);
        },
        // 获取代码模板
        getCodeTemplateList() {
            this.cmLoadingState = true;
            this.codeTemplateController.getCodeTemplateList(this.token, '',
                this.project.codeTemplateGroupId,
                '', 0, -1).then(async (res: CodeTemplateList) => {
                    if (res) {
                        this.codeTemplateList = new CodeTemplateList(res);
                        // 先去获取代码模板 再去获取模型定义 ( 因为监听器中需要用到代码模板数据)
                        this.modelDefineList = await this.getModelDefineList();
                        if (this.modelDefineList) {
                            if (this.modelDefineList.count > 0 && !this.selectModelDefine.id) {
                                this.selectModelDefine = this.modelDefineList.first;
                                this.menuSelectKeys = [this.selectModelDefine.id];
                                // 获取关系
                                this.getModelDefineTemplateRelationByModelDefineId();
                            }
                        }
                    }
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                }).finally(() => {
                    this.cmLoadingState = false;
                });
        },
        // 生成代码
        getGenerateCode() {
            if (!this.selectModelDefine.id || !this.activeTabByCodeTemplateId) {
                return this.cmMsgSvr.warn('请先检查模型与模板是否选中');
            }
            this.loadCodeLoading = true;
            this.codeGenerateController.generateCode(this.token, this.selectModelDefine.id, this.activeTabByCodeTemplateId).then((res) => {
                this.generateCode = res;
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.loadCodeLoading = false;
            });
        },
        // 生成全部代码
        getGenerateAllCode() {
            this.loadCodeLoading = true;
            this.codeGenerateController.generateAllCode(this.token, this.selectModelDefine.id, this.activeTabByCodeTemplateId).then((res) => {
                if (res) {
                    res.download();
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.loadCodeLoading = false;
            });
        },
        getModelDefineTemplateRelationByModelDefineId() {
            this.loadRelationLoading = true;
            this.modelDefineTemplateRelationController.getModelDefineTemplateRelationByModelDefineId(this.token,
                this.project.code,
                this.selectModelDefine.id).then((res: ModelDefineTemplateRelation) => {
                    if (res && res.codeTemplateIds.length > 0) {
                        this.selectCodeTempList = this.codeTemplateList.filter(item => res.codeTemplateIds.includes(item.id));
                        if (this.selectCodeTempList.length > 0 && !this.activeTabByCodeTemplateId) {
                            this.activeTabByCodeTemplateId = this.selectCodeTempList[0].id;
                            this.getGenerateCode();
                        }
                    }
                    this.cmSysSvr.sendMessage('page-setSelectCodeTempKeys', res?.codeTemplateIds || []);
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                }).finally(() => {
                    this.loadRelationLoading = false;
                });
        },
        setRelation(modelDefineId: string, codeTemplateIds: string[]) {
            return this.modelDefineTemplateRelationController.setRelation(this.token, this.project.code, modelDefineId, codeTemplateIds);
        },
        // codeTemplateIds: string[] 父组件传来
        setModelDefineAndCodeTemplateRelation(codeTemplateIds: string[]) {
            if (this.selectModelDefine.id) {
                // 选择了模板才能设置关系
                this.setRelation(this.selectModelDefine.id, codeTemplateIds).catch(err => {
                    this.cmMsgSvr.error(err.message);
                });
            }
        },
        // 选择左侧模型
        onSelectModelDefine({ key }) {
            this.generateCode = '';
            this.activeTabByCodeTemplateId = '';
            this.selectCodeTempKeys = [];
            this.selectCodeTempList = [];

            this.selectModelDefine = this.modelDefineList.findItem('id', key);
            if (!this.selectModelDefine) {
                return this.cmMsgSvr.error('未找到对应的模型定义对象');
            }
            this.getModelDefineTemplateRelationByModelDefineId();
            // 之后交给监听器去处理更细的数据
        },
        // 由父组件中转调用
        selectCodeTempChange(values) {
            this.selectCodeTempList = this.filterCodeTemplateList.filter(item => values.includes(item.id));
            if (this.selectCodeTempList.length <= 0) {
                this.activeTabByCodeTemplateId = '';
            } else if (!this.activeTabByCodeTemplateId) {
                // tab默认选中第一个
                this.activeTabByCodeTemplateId = this.selectCodeTempList[0].id;
                this.getGenerateCode();
            }
        },
        // 由父组件中转调用
        deleteCodeTemplate(values, deleteOption) {
            if (deleteOption.value === this.activeTabByCodeTemplateId) {
                if (this.selectCodeTempList.length > 0) {
                    this.activeTabByCodeTemplateId = this.selectCodeTempList[0].id;
                    this.getGenerateCode();
                }
            }
        },
        // tabs回调
        codeTemplateChange() {
            this.generateCode = '';
            this.getGenerateCode();
        },
        onCopyGenerateCode() {
            const findCodeTemp = this.selectCodeTempList.find(item => item.id === this.activeTabByCodeTemplateId);
            if (!findCodeTemp) {
                return this.cmMsgSvr.warn('未找到要复制的代码');
            }
            this.toClipboard(this.generateCode).then(() => {
                this.cmMsgSvr.success(`复制代码[${findCodeTemp.name}]成功`);
            }).catch(err => {
                this.cmMsgSvr.error(`复制代码[${findCodeTemp.name}]失败 - ${err.message}`);
            });
        }
    }

});
