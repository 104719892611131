import {BaseEntity} from 'cmt-vue-lib';

export class BaseInfo extends BaseEntity {
    id: string;
    name: string;
    $type: string;

    constructor(options: {
        id?: string;
        name?: string;
        $type?: string;
    } = {}) {
        super();
        this.id = options.id || undefined;
        this.name = options.name || undefined;
        this.$type = options.$type || undefined;
    }
}
