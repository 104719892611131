
import {defineComponent, provide, markRaw, computed} from 'vue';

import ElementBaseInfo from './modules/baseInfo/ElementBaseInfo.vue';
import ElementProperties from './modules/properties/ElementProperties.vue';
import ElementOtherInfo from './modules/ElementOtherInfo.vue';
import ElementListener from './modules/listener/ElementListener.vue';
import SignalAndMassage from './modules/signal-message/SignalAndMassage.vue';


export default defineComponent({
  name: "DesignerPanel",
  components: {
    ElementBaseInfo,
    ElementProperties,
    ElementOtherInfo,
    ElementListener,
    SignalAndMassage
  },
  props: ['designerInstance'],
  setup(props) {
    provide('size', 'small');
    provide('prefix', 'camunda');
    provide('designerInstance', computed(() => props.designerInstance));
    provide('modeling', computed(() => props.designerInstance?.get('modeling')));
    provide('moddle', computed(() => props.designerInstance?.get('moddle')));
    provide('eventBus', computed(() => props.designerInstance?.get('eventBus')));
    provide('bpmnFactory', computed(() => props.designerInstance?.get('bpmnFactory')));
    provide('elementFactory', computed(() => props.designerInstance?.get('elementFactory')));
    provide('elementRegistry', computed(() => props.designerInstance?.get('elementRegistry')));
    provide('replace', computed(() => props.designerInstance?.get('replace')));
    provide('selection', computed(() => props.designerInstance?.get('selection')));
    return {};
  },
  data() {
    return {
      activeElement: null,
      activeKey: [],
    };
  },
  computed: {
    collapsible() {
      return !this.designerInstance || !this.activeElement ? 'disabled' : '';
    },
    elementType() {
      return this.activeElement?.type || "";
    }
  },
  watch: {
    'activeElement.id'() {
      if (!this.activeKey.includes('base')) {
        this.activeKey.push('base');
      }
    }
  },
  created() {
    this.initModels();
  },
  methods: {
    initModels() {
      if (!this.designerInstance) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.initModels(), 10);
        return;
      }
      if (this.timer) clearTimeout(this.timer);


      this.initActiveElement();
    },
    initActiveElement() {
      // 监听xml导入完成
      this.designerInstance.on("import.done", e => {
        this.onElementSelect(null);
      });

      // 监听元素改变
      this.designerInstance.on("selection.changed", ({newSelection}) => {
        this.onElementSelect(newSelection[0] || null);
      });

    },
    /* 当元素被选择 */
    onElementSelect(el: any) {
      const elementRegistry = this.designerInstance.get('elementRegistry');
      let activeElement = el;
      if (!activeElement) {
        activeElement = elementRegistry.find(el => el.type === "bpmn:Process") ??
            elementRegistry.find(el => el.type === "bpmn:Collaboration");
      }
      if (!activeElement) {
        return;
      }
      this.activeElement = markRaw(activeElement);
    }
  }
});
