
import {defineComponent, PropType, provide, toRefs} from 'vue';
import {IButton, IViewConfig} from './types';
import {sysSvr, Utils} from 'cmt-vue-lib';

export default defineComponent({
  name: "ViewBase",
  title: "加载中...",
  single: false,
  props: {
    props: {
      type: Object as PropType<IViewConfig>
    }
  },
  setup(props) {
    const {props: viewConfig} = toRefs(props);
    provide('SourceViewConfig', Utils.deepClone<IViewConfig>(viewConfig.value));
    provide('ViewConfig', viewConfig.value);
    const emitAction = (btn: IButton) => {
      sysSvr.sendMessage(btn.action, btn);
    };
    return {
      viewConfig,
      emitAction
    };
  }
});
