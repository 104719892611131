import {DataList} from 'cmt-vue-lib';
import {BaseEntity} from 'cmt-vue-lib';

export class InterfaceDefineParam extends BaseEntity {
    id: string; // ID
    index: number; // 索引
    name: string; // 参数名称
    variableName: string; // 参数变量名称
    variableType: string; // 参数变量类型（根据语言类型分类）
    constructor(options: any = {}) {
        super();
        this.id = options?.id || '';
        this.index = options?.index || 0;
        this.name = options?.name || '';
        this.variableName = options?.variableName || '';
        this.variableType = options?.variableType || '';
    }
}

export class InterfaceDefineParamList extends DataList<InterfaceDefineParam> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new InterfaceDefineParam(item));
            }
        }
    }
}
