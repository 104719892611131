import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsViewer = _resolveComponent("ReportsViewer")!
  const _component_cm_card_body = _resolveComponent("cm-card-body")!
  const _component_cm_card = _resolveComponent("cm-card")!

  return (_openBlock(), _createBlock(_component_cm_card, { class: "home" }, {
    default: _withCtx(() => [
      _createVNode(_component_cm_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_ReportsViewer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}