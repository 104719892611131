
import {defineComponent, inject, markRaw, ref} from 'vue';
import type {TableColumnsType} from "ant-design-vue";
import {message} from 'ant-design-vue';
import {Message} from "./message.domain";
import {Signal} from "./signal.domain";
import {isNCName} from '../../../utils';

export default defineComponent({
  name: "SignalAndMassage",
  props: ['activeElement'],
  setup() {
    const size = inject('size', 'small');
    const prefix = inject('prefix', 'camunda');
    const designerInstance = inject('designerInstance', null);
    const moddle = inject('moddle', null);

    const msgRules = {
      id: [{required: true, message: '请输入消息标识!', trigger: ['change', 'blur']}],
      name: [{required: true, message: '请输入消息名称!', trigger: ['change', 'blur']}],
    };

    const signalRules = {
      id: [{required: true, message: '请输入信号标识!', trigger: ['change', 'blur']}],
      name: [{required: true, message: '请输入信号名称!', trigger: ['change', 'blur']}],
    };

    const msgColumns = ref<TableColumnsType>([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        customRender: ({text, record, index}) => `${index + 1}`,
      },
      {
        key: 'id',
        dataIndex: 'id',
        align: 'center',
        title: '消息ID',
        ellipsis: true,
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 100,
      },
    ]);
    const signalColumns = ref<TableColumnsType>([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        customRender: ({text, record, index}) => `${index + 1}`,
      },
      {
        key: 'id',
        dataIndex: 'id',
        align: 'center',
        title: '信号ID',
        ellipsis: true,
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 100,
      },
    ]);

    return {
      size,
      prefix,
      msgColumns,
      signalColumns,
      msgRules,
      signalRules,
      designerInstance,
      moddle
    };
  },
  data() {
    return {
      messageEditState: false,
      messageModalVisible: false,
      message: new Message(),

      signalEditState: false,
      signalModalVisible: false,
      signal: new Signal(),

      messageList: [],
      signalList: [],
      rootElements: [] as any[],
    };
  },
  mounted() {
    this.initRootElements();
  },
  methods: {
    initRootElements() {
      this.rootElements = markRaw(this.designerInstance.getDefinitions().rootElements);
      this.messageList = this.rootElements.filter(item => item.$type === "bpmn:Message");
      this.signalList = this.rootElements.filter(item => item.$type === "bpmn:Signal");
    },
    onNewOrEditMessageModel(item?: Message) {
      if (!item) {
        this.messageEditState = false;
        this.message = new Message();
      } else {
        this.messageEditState = true;
        this.message = new Message(item);
      }
      this.messageModalVisible = true;
    },
    onDeleteMessageSubmit(item: Message) {
      const findIndex = this.rootElements.findIndex(el => el.id === item.id);
      if (findIndex >= 0) {
        this.rootElements.splice(findIndex, 1);
      }
      this.initRootElements();
    },
    onNewOrEditMessageSubmit() {
      this.$refs.messageForm.validate().then(() => {
        if (!isNCName(this.message.id)) {
          return message.error('消息标识不满足XML NCName规则，所以不进行赋值');
        }
        if (this.messageEditState) {
          const findIndex = this.rootElements.findIndex(item => item.id === this.message.id);
          if (findIndex >= 0) {
            const findEl = this.rootElements[findIndex];
            if (findEl.id !== this.message.id) {
              if (this.rootElements.find(item => item.id === this.message.id && item.$type === "bpmn:Message")) {
                return message.error(`消息标识[${this.message.id}]已经存在`);
              }
            } else {
              this.rootElements.splice(findIndex, 1, Object.assign(findEl, this.message));
            }
          }
        } else {
          if (this.rootElements.find(item => item.id === this.message.id && item.$type === "bpmn:Message")) {
            return message.error(`消息标识[${this.message.id}]已经存在`);
          }
          const msgEl = this.moddle.create("bpmn:Message", this.message);
          this.rootElements.push(msgEl);
        }
        this.initRootElements();
        this.messageModalVisible = false;
      });
    },

    onNewOrEditSignalModel(item?: Signal) {
      if (!item) {
        this.signalEditState = false;
        this.signal = new Signal();
      } else {
        this.signalEditState = true;
        this.signal = new Signal(item);
      }
      this.signalModalVisible = true;
    },
    onDeleteSignalSubmit(item: Signal) {
      const findIndex = this.rootElements.findIndex(el => el.id === item.id);
      if (findIndex >= 0) {
        this.rootElements.splice(findIndex, 1);
      }
      this.initRootElements();
    },
    onNewOrEditSignalSubmit() {
      this.$refs.signalForm.validate().then(() => {
        if (!isNCName(this.signal.id)) {
          return message.error('信号标识不满足XML NCName规则，所以不进行赋值');
        }
        if (this.signalEditState) {
          const findIndex = this.rootElements.findIndex(item => item.id === this.signal.id);
          if (findIndex >= 0) {
            const findEl = this.rootElements[findIndex];
            if (findEl.id !== this.signal.id) {
              if (this.rootElements.find(item => item.id === this.signal.id && item.$type === "bpmn:Signal")) {
                return message.error(`信号标识[${this.signal.id}]已经存在`);
              }
            } else {
              this.rootElements.splice(findIndex, 1, Object.assign(findEl, this.signal));
            }
          }
        } else {
          if (this.rootElements.find(item => item.id === this.signal.id && item.$type === "bpmn:Signal")) {
            return message.error(`信号标识[${this.signal.id}]已经存在`);
          }
          const msgEl = this.moddle.create("bpmn:Signal", this.signal);
          this.rootElements.push(msgEl);
        }
        this.initRootElements();
        this.signalModalVisible = false;
      });

    }
  }
});
