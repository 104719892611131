import {defineComponent, ref} from 'vue';
import {CmBaseComponent} from 'cmt-vue-lib';
import type {TableColumnsType} from 'ant-design-vue';
import {ProcessTemplateController} from '@/services/process-template.controller';
import {ProcessTemplate, ProcessTemplateList} from '@/domains/process-template.domain';
import {Utils, SysDictController, DictTypeEnum, DictValueList} from 'cmt-vue-lib';

import ProcessDesigner from "@/components/process-designer/src/designer/ProcessDesigner.vue";
import ProcessViewer from "@/components/process-designer/src/designer/ProcessViewer.vue";

export default defineComponent({
    /* 组件名称 */
    name: 'DesignManage',
    /* Tab页标题 */
    title: '流程管理',
    /* 单例模式 */
    single: false,
    /* 继承 */
    extends: CmBaseComponent,
    /* 局部组件 */
    components: {
        ProcessDesigner,
        ProcessViewer
    },
    /* 参数 */
    props: ['props'],
    /* API */
    setup() {
        const columns = ref<TableColumnsType>([
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                customRender: ({text, record, index}) => `${index + 1}`,
            },
            {
                key: 'id',
                dataIndex: 'id',
                align: 'center',
                title: '流程标识',
                ellipsis: true
            },
            {
                key: 'name',
                dataIndex: 'name',
                align: 'center',
                title: '流程名称'
            },
            {
                key: 'category',
                dataIndex: 'category',
                align: 'center',
                title: '流程分类'
            },
            {
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                title: '流程状态',
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 300,
            },
        ]);

        const rules = {
            name: [{required: true, message: '请输入项目名称!', trigger: ['change', 'blur']}],
            category: [{required: true, message: '请选择流程分类!', trigger: ['change', 'blur']}],
            bpmnModelXml: [{required: false, message: '请上传流程模型!', trigger: ['change', 'blur']}],
            status: [{required: false, message: '请选择流程状态!', trigger: ['change', 'blur']}],
        };

        return {
            columns,
            rules
        };
    },
    /* 数据源 */
    data() {
        return {
            formLoading: false,
            processEditState: false,
            processDesignVisible: false, // 流程设计 - 模态框
            processStartLoading: false, // 流程设计 - 模态框加载
            processViewerVisible: false, // 流程预览 - 模态框
            processViewerLoading: false, // 流程预览 - 模态框加载
            processTemplateController: new ProcessTemplateController(),
            sysDictController: new SysDictController(),
            processTemplateList: new ProcessTemplateList(),
            processTemplate: new ProcessTemplate(),
            processFile: null as typeof File,
            processCategoryDictList: new DictValueList(), // 字典 - 流程分类
            processStatus: null, // 状态 - 流程状态
            activeProcessTemplate: new ProcessTemplate() // 流程设计/流程预览 - 当前设计对象
        };
    },
    created() {
        this.getProcessTemplateList();
        this.getDictListByLanguageType();
    },
    methods: {
        getDictListByLanguageType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.PROCESS_CATEGORY, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.processCategoryDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getProcessTemplateList() {
            this.cmLoadingState = true;
            this.processTemplateController.getProcessTemplateList(this.token,
                this.cmKeyWord,
                this.processStatus,
                this.cmPageBean.currentPage - 1,
                this.cmPageBean.pageSize).then(res => {
                if (res) {
                    this.processTemplateList = res;
                } else {
                    this.processTemplateList = new ProcessTemplateList();
                }
                this.cmPageBean.totalCount = this.processTemplateList.totalCount;
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmLoadingState = false;
            });
        },
        getProcessTemplate() {
            this.formLoading = true;
            this.processTemplateController.getProcessTemplate(this.token, this.processTemplate.id).then(res => {
                if (res) {
                    this.processTemplate = res;
                    if (this.processTemplate?.bpmnModelXml) {
                        this.processFile = Utils.textToFile(this.processTemplate.bpmnModelXml, this.processTemplate.name + '.xml');
                    }
                } else {
                    this.processTemplate = new ProcessTemplate();
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.formLoading = false;
            });
        },
        /* 新增/编辑流程 - 打开模态框 */
        onOpenNewOrEditModel(item?: ProcessTemplate) {
            if (!(item instanceof ProcessTemplate)) {
                this.processEditState = false;
                this.processTemplate = new ProcessTemplate();
            } else {
                this.processEditState = true;
                this.processTemplate = new ProcessTemplate(item);
                this.getProcessTemplate();
            }
            this.cmModalVisible = true;
        },
        /* 新增/编辑流程 - 提交 */
        async onNewOrEditProcessSubmit() {
            if (await Utils.formValidate(this.$refs.newProcessRef)) {
                if (!this.processEditState) {
                    this.cmButtonLoading = true;
                    if (this.processFile) {
                        this.processTemplate.bpmnModelXml = await Utils.fileToText(this.processFile);
                    }
                    this.processTemplateController.addProcessTemplate(this.token, this.processTemplate).then(res => {
                        if (res) {
                            this.cmMsgSvr.success('新增流程模板成功');
                            this.cmModalVisible = false;
                            this.getProcessTemplateList();
                        }
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        // this.processFile = null;
                        this.cmButtonLoading = false;
                    });
                } else {
                    this.cmModalSvr.confirm({
                        iconType: 'warning',
                        title: '操作提示',
                        content: '您确定要保存编辑后的数据吗？',
                        centered: true,
                        onOk: () => {
                            return new Promise<boolean>(async (resolve) => {

                                if (this.processFile) {
                                    this.processTemplate.bpmnModelXml = await Utils.fileToText(this.processFile);
                                } else {
                                    this.processTemplate.bpmnModelXml = '';
                                }

                                this.processTemplateController.modifyProcessTemplate(this.token, this.processTemplate.id, this.processTemplate).then(() => {
                                    this.cmMsgSvr.success('编辑项目信息成功');
                                    this.cmModalVisible = false;
                                    this.getProcessTemplateList();
                                    resolve(true);
                                }).catch(err => {
                                    this.cmMsgSvr.error(err.message);
                                    resolve(false);
                                });
                            });
                        }
                    });
                }
            }
        },
        /* 刷新重置列表 */
        resetProcessList() {
            this.cmKeyWord = '';
            this.processStatus = null;
            this.getProcessTemplateList();
        },
        /* 改变流程状态 */
        onChangeSwitch(v: boolean, item: ProcessTemplate & { loading: boolean }) {
            Object.assign(item, {loading: true});
            this.processTemplateController.setProcessTemplateStatus(this.token, item.id, v).then(() => {
                this.cmMsgSvr.success(`[${item.name}]流程模板设置状态为[${v ? '开启' : '关闭'}]成功`);
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                delete item.loading;
            });
        },
        /* 删除流程 */
        onDeleteProcessSubmit(item: ProcessTemplate) {
            return new Promise((resolve) => {
                this.processTemplateController.deleteProcessTemplate(this.token, item.id).then(() => {
                    this.cmMsgSvr.success('删除流程模板成功');
                    this.getProcessTemplateList();
                    resolve(true);
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                    resolve(false);
                });
            });
        },
        /* 发布流程 */
        onPublishProcess(item: ProcessTemplate) {
            this.cmModalSvr.confirm({
                iconType: 'warning',
                title: '操作提示',
                content: `您确定要发布[${item.name}]流程吗？`,
                centered: true,
                onOk: () => {
                    return new Promise<boolean>(async (resolve) => {
                        this.processTemplateController.publishProcessTemplate(this.token, item.id).then(() => {
                            this.cmMsgSvr.success('发布流程成功');
                            this.getProcessTemplateList();
                            resolve(true);
                        }).catch(err => {
                            this.cmMsgSvr.error(err.message);
                            resolve(false);
                        });
                    });
                }
            });

        },
        /* 设计流程开始 */
        onStartProcessDesign(item: ProcessTemplate) {
            this.activeProcessTemplate = null;
            this.processDesignVisible = true;
            this.processStartLoading = true;

            this.processTemplateController.getProcessTemplate(this.token, item.id).then(res => {
                this.activeProcessTemplate = res;
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.processStartLoading = false;
            });
        },
        /* 预览流程开始 */
        onPreviewProcessDesign(item: ProcessTemplate) {
            this.activeProcessTemplate = null;
            this.processViewerVisible = true;
            this.processViewerLoading = true;

            this.processTemplateController.getProcessTemplate(this.token, item.id).then(res => {
                this.activeProcessTemplate = res;
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.processViewerLoading = false;
            });

        },
        /* 当模型被保存时 */
        onProcessDesignSave(xml: string) {
            const msgHide = this.cmMsgSvr.loading('流程模型保存中...', 0);
            this.processTemplateController.modifyBpmnModel(this.token, this.activeProcessTemplate.id, xml).then(() => {
                this.cmMsgSvr.success('流程模型保存成功');
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(msgHide);
        },
        /* 下载流程文件 */
        processFileDownload() {
            if (this.processFile) {
                Utils.downloadByFile(this.processFile);
            }
        },
        /* 当预览/设计流程组件出现导入错误 */
        onProcessError() {
            if (this.processViewerVisible) {
                this.cmMsgSvr.error('导入的文件可能是不符合流程规范的文件');
            } else {
                this.cmMsgSvr.warn('流程文件内容有误，请重新上传流程文件或[重绘]流程后进行流程设计');
            }
        }
    }
});
