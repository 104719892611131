import { request } from 'cmt-vue-lib';
import { ModelDefine, ModelDefineList } from '@/domains/model-define.domain';
import { InterfaceDefineItemList } from '@/domains/interface-define-item.domain';
import { ModelDefineItemList } from '@/domains/model-define-item.domain';

/**
 * 模型定义管理
 * <p>
 * description 模型定义管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 14:16:05
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ModelDefineController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'ModelDefineController.svc/';
    }

    // region 自动生成代码

    /**
     * 删除模型定义
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     */
    public deleteModelDefine(token: string, id: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteModelDefine', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 修改模型定义
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param name {string} 名称
     * @param description {string} 描述
     * @param modelType {number} 模型类型
     * @param packageName {string} 包名
     * @param clazzName {string} 类名
     * @param parentClazzName {string} 父类名称
     * @param tableName {string} 表名
     * @param generateDbModel {boolean} 生成数据库模型
     * @param dbDefineUpperCase {boolean} 数据库列是否大写
     */
    public modifyModelDefine(token: string, id: string, name: string, description: string, modelType: number, packageName: string, clazzName: string, parentClazzName: string, tableName: string, generateDbModel: boolean, dbDefineUpperCase: boolean): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('modelType', modelType?.toString() || '');
        formData.append('packageName', packageName?.toString() || '');
        formData.append('clazzName', clazzName?.toString() || '');
        formData.append('parentClazzName', parentClazzName?.toString() || '');
        formData.append('tableName', tableName?.toString() || '');
        formData.append('generateDbModel', generateDbModel?.toString() || '');
        formData.append('dbDefineUpperCase', dbDefineUpperCase?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyModelDefine', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取模型定义
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     */
    public getModelDefine(token: string, id: string): Promise<ModelDefine> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<ModelDefine>(this.baseurl + 'getModelDefine', formData
        ).then(res => {
            return !!res ? new ModelDefine(res) : null;
        });
    }

    /**
     * 设置接口定义
     *
     * @param token {string} 用户令牌
     * @param id {string} id
     * @param interfaceDefineItems {InterfaceDefineItemList} 接口定义列表
     */
    public setInterfaceDefineItems(token: string, id: string, interfaceDefineItems: InterfaceDefineItemList): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('interfaceDefineItems', interfaceDefineItems?.toString() || '');
        return request.post<void>(this.baseurl + 'setInterfaceDefineItems', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取模型定义列表
     *
     * @param token {string} 用户令牌
     * @param modelType{number}模型类型
     * @param keyWord {string} 关键字
     * @param projectCode {string} 项目编码
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getModelDefineList(token: string, modelType: number, keyWord: string, projectCode: string, startIndex: number, maxSize: number): Promise<ModelDefineList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('modelType', modelType?.toString() || '-1');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<ModelDefineList>(this.baseurl + 'getModelDefineList', formData
        ).then(res => {
            return !!res ? new ModelDefineList(res) : null;
        });
    }

    /**
     * 添加模型定义
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     * @param name {string} 名称
     * @param description {string} 描述
     * @param modelType {number} 模板类型
     * @param packageName {string} 包名
     * @param clazzName {string} 类名
     * @param parentClazzName {string} 父类名称
     * @param tableName {string} 表名
     */
    public addModelDefine(token: string, projectCode: string, name: string, description: string, modelType: number, packageName: string, clazzName: string, parentClazzName: string, tableName: string): Promise<ModelDefine> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('modelType', modelType?.toString() || '');
        formData.append('packageName', packageName?.toString() || '');
        formData.append('clazzName', clazzName?.toString() || '');
        formData.append('parentClazzName', parentClazzName?.toString() || '');
        formData.append('tableName', tableName?.toString() || '');
        return request.post<ModelDefine>(this.baseurl + 'addModelDefine', formData
        ).then(res => {
            return !!res ? new ModelDefine(res) : null;
        });
    }

    /**
     * 设置模板定义
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param modelDefineItems {ModelDefineItemList} 模板定义列表
     */
    public setModelDefineItems(token: string, id: string, modelDefineItems: ModelDefineItemList): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('modelDefineItems', modelDefineItems?.toString() || '');
        return request.post<void>(this.baseurl + 'setModelDefineItems', formData).then(res => {
            return res;
        });
    }


    /**
     * 根据数据库表结构生成模型定义
     *
     * @param token {string} 用户令牌
     * @param repositoryId {string} 数据库仓库ID
     * @param tableNames {string} 表名称
     * @param columnLowercase {boolean} 列是否转小写
     * @param removeConnSym {boolean} 去掉连接符
     */
    public generateModelDefine(token: string, repositoryId: string, tableNames: string[], columnLowercase: boolean, removeConnSym: boolean): Promise<ModelDefineList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('repositoryId', repositoryId?.toString() || '');
        formData.append('tableNames', JSON.stringify(tableNames || []));
        formData.append('columnLowercase', columnLowercase?.toString() || '');
        formData.append('removeConnSym', columnLowercase?.toString() || '');
        return request.post<ModelDefineList>(this.baseurl + 'generateModelDefine', formData
        ).then(res => {
            return !!res ? new ModelDefineList(res) : null;
        });
    }

    // endregion

}
