import {InterfaceDefineParamList} from '@/domains/interface-define.domain';
import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';

/**
 * 接口定义项
 *
 */
export class InterfaceDefineItem extends BaseDataEntity {
    id: string; // Id
    index: number; // 索引
    name: string; // 名称
    variableName: string; // 变量名称
    returnType: string; // 返回类型
    publish: boolean; // 是否发布
    interfaceDefineParams: InterfaceDefineParamList; // 接口参数
    constructor(options: any = {}) {
        super(options);
        this.id = options?.id || '';
        this.index = options?.index || 0;
        this.name = options?.name || '';
        this.variableName = options?.variableName || '';
        this.returnType = options?.returnType || '';
        this.publish = !!options?.publish;
        this.interfaceDefineParams = new InterfaceDefineParamList(options?.interfaceDefineParams || {});
    }
}

export class InterfaceDefineItemList extends DataList<InterfaceDefineItem> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new InterfaceDefineItem(item));
            }
        }
    }
}
