import {request} from 'cmt-vue-lib';
import {ProjectStructure, ProjectStructureList} from '@/domains/project-structure.domain';

/**
 * 项目结构管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 14:38:14
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProjectStructureMgeController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'ProjectStructureMgeController.svc/';
    }

    // region 自动生成代码

    /**
     * 添加项目结构
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     * @param parentId {string} 父节点Id
     * @param name {string} 名称
     * @param description {string} 描述
     * @param type {string} 类型
     * @param index {number} 索引
     */
    public addProjectStructure(token: string, projectCode: string, parentId: string, name: string, description: string, type: string, index: number): Promise<ProjectStructure> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('parentId', parentId?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('type', type?.toString() || '');
        formData.append('index', index?.toString() || '');
        return request.post<ProjectStructure>(this.baseurl + 'addProjectStructure', formData
        ).then(res => {
            return !!res ? new ProjectStructure(res) : null;
        });
    }

    /**
     * 删除项目结构
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param includeChildren {boolean} 是否包含子集
     */
    public deleteProjectStructure(token: string, id: string, includeChildren: boolean): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('includeChildren', includeChildren?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteProjectStructure', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 通过项目编码获取项目结构列表
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     */
    public getProjectStructureListByProjectCode(token: string, projectCode: string): Promise<ProjectStructureList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        return request.post<ProjectStructureList>(this.baseurl + 'getProjectStructureListByProjectCode', formData
        ).then(res => {
            return !!res ? new ProjectStructureList(res) : null;
        });
    }

    /**
     * 设置项目结构的父节点
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param parentId {string} 父节点Id
     */
    public setProjectStructureParentId(token: string, id: string, parentId: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('parentId', parentId?.toString() || '');
        return request.post<void>(this.baseurl + 'setProjectStructureParentId', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 复制项目结构
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param parentId {string} 父节点Id
     */
    public copyProjectStructure(token: string, id: string, parentId: string): Promise<ProjectStructure> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('parentId', parentId?.toString() || '');
        return request.post<ProjectStructure>(this.baseurl + 'copyProjectStructure', formData
        ).then(res => {
            return !!res ? new ProjectStructure(res) : null;
        });
    }

    /**
     * 修改项目结构
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param parentId {string} 父节点Id
     * @param name {string} 名称
     * @param description {string} 描述
     * @param modelDefineId {string} 模型定义Id
     * @param index {number} 索引
     */
    public modifyProjectStructure(token: string, id: string, parentId: string, name: string, description: string, modelDefineId: string, index: number): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('parentId', parentId?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('modelDefineId', modelDefineId?.toString() || '');
        formData.append('index', index?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyProjectStructure', formData
        ).then(res => {
            return res;
        });
    }

    // endregion

}
