import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cm_icon = _resolveComponent("cm-icon")!
  const _component_ElementBaseInfo = _resolveComponent("ElementBaseInfo")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_SignalAndMassage = _resolveComponent("SignalAndMassage")!
  const _component_ElementListener = _resolveComponent("ElementListener")!
  const _component_ElementProperties = _resolveComponent("ElementProperties")!
  const _component_ElementOtherInfo = _resolveComponent("ElementOtherInfo")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_cm_card_body = _resolveComponent("cm-card-body")!
  const _component_cm_card = _resolveComponent("cm-card")!

  return (_openBlock(), _createBlock(_component_cm_card, { class: "designer-panel" }, {
    default: _withCtx(() => [
      _createVNode(_component_cm_card_body, { style: {padding:'0 2px'} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_collapse, {
            activeKey: _ctx.activeKey,
            "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_collapse_panel, {
                collapsible: _ctx.collapsible,
                key: "base",
                header: "常规"
              }, {
                extra: _withCtx(() => [
                  _createVNode(_component_cm_icon, { icon: "InfoCircleOutlined" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ElementBaseInfo, { activeElement: _ctx.activeElement }, null, 8, ["activeElement"])
                ]),
                _: 1
              }, 8, ["collapsible"]),
              (_ctx.elementType.indexOf('Process')!==-1)
                ? (_openBlock(), _createBlock(_component_a_collapse_panel, {
                    collapsible: _ctx.collapsible,
                    key: "message",
                    header: "消息与信号"
                  }, {
                    extra: _withCtx(() => [
                      _createVNode(_component_cm_icon, { icon: "AlertOutlined" })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_SignalAndMassage, { activeElement: _ctx.activeElement }, null, 8, ["activeElement"])
                    ]),
                    _: 1
                  }, 8, ["collapsible"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_collapse_panel, {
                collapsible: _ctx.collapsible,
                key: "listeners",
                header: "监听器"
              }, {
                extra: _withCtx(() => [
                  _createVNode(_component_cm_icon, { icon: "BellOutlined" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ElementListener, { activeElement: _ctx.activeElement }, null, 8, ["activeElement"])
                ]),
                _: 1
              }, 8, ["collapsible"]),
              _createVNode(_component_a_collapse_panel, {
                collapsible: _ctx.collapsible,
                key: "extensions",
                header: "扩展属性"
              }, {
                extra: _withCtx(() => [
                  _createVNode(_component_cm_icon, { icon: "PlusCircleOutlined" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ElementProperties, { activeElement: _ctx.activeElement }, null, 8, ["activeElement"])
                ]),
                _: 1
              }, 8, ["collapsible"]),
              _createVNode(_component_a_collapse_panel, {
                collapsible: _ctx.collapsible,
                key: "other",
                header: "其它"
              }, {
                extra: _withCtx(() => [
                  _createVNode(_component_cm_icon, { icon: "SendOutlined" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ElementOtherInfo, { activeElement: _ctx.activeElement }, null, 8, ["activeElement"])
                ]),
                _: 1
              }, 8, ["collapsible"])
            ]),
            _: 1
          }, 8, ["activeKey"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}