import {BaseEntity, DataList} from 'cmt-vue-lib';

export class Field extends BaseEntity {
    id: string
    fieldName: string;
    fieldType: string;
    fieldValue: string;
    fieldExpression: string;
    expressOrValue: string;

    constructor(options: {
        id?: string;
        fieldName?: string;
        fieldType?: string;
        fieldValue?: string;
        fieldExpression?: string;
        expressOrValue?: string;
    } = {}) {
        super();
        this.id = options.id || new Date().getTime().toString();
        this.fieldName = options.fieldName || undefined;
        this.fieldType = options.fieldType || undefined;
        this.fieldValue = options.fieldValue || undefined;
        this.fieldExpression = options.fieldExpression || undefined;
        this.expressOrValue = options.fieldValue || options.fieldExpression || undefined;
    }
}

export class FieldList extends DataList<Field> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new Field(item));
            }
        }
    }
}