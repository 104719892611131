
import {defineComponent, ref, onMounted, watch, markRaw} from 'vue';
import Spreadsheet from "x-data-spreadsheet";

export default defineComponent({
  name: "ReportsViewer",
  props: {
    dataSource: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup({dataSource}) {
    const canvasRef = ref<HTMLCanvasElement>(null);
    const spreadsheet = ref<Spreadsheet>(null);

    onMounted(() => {
      const canvasRect: DOMRect = canvasRef.value.getBoundingClientRect();
      spreadsheet.value = new Spreadsheet(canvasRef.value, {
        mode: 'read',
        showGrid: false,
        showToolbar: false,
        showContextmenu: false,
        showBottomBar: false,
        view: {
          height: () => canvasRect.height,
          width: () => canvasRect.width
        }
      });

      loadDataSource(null);
    });

    const loadDataSource = (data?: Record<string, any>) => {
      if (spreadsheet.value) {
        spreadsheet.value.loadData(JSON.parse(JSON.stringify(data || dataSource)));
      }
    };

    return {
      canvasRef,
      loadDataSource
    };
  }
});
