
import {defineComponent, inject} from 'vue';

export default defineComponent({
  name: "ElementOtherInfo",
  props: ['activeElement'],
  setup() {
    const bpmnFactory = inject('bpmnFactory', null);
    const modeling = inject('modeling', null);
    return {bpmnFactory, modeling};
  },
  data() {
    return {
      documentation: ''
    };
  },
  watch: {
    'activeElement.id': {
      handler(val: string) {
        if (val && val.length) {
          this.$nextTick(() => {
            const documentations = this.activeElement.businessObject?.documentation;
            this.documentation = documentations && documentations.length ? documentations[0].text : "";
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    updateDocumentation() {
      const documentation = this.bpmnFactory.create("bpmn:Documentation", {text: this.documentation});
      this.modeling.updateProperties(this.activeElement, {
        documentation: documentation ? [documentation] : []
      });
    }
  }
});
