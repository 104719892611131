import {request} from 'cmt-vue-lib';
import {Project, ProjectList} from '@/domains/project.domain';

/**
 * 项目管理
 * <p>
 * description 项目管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 14:28:33
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProjectMgeController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'ProjectMgeController.svc/';
    }

    // region 自动生成代码

    /**
     * 删除项目
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     * @param includeChildren {boolean} 是否有子集
     */
    public deleteProject(token: string ,projectCode: string ,includeChildren: boolean): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('includeChildren', includeChildren?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteProject', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 创建项目
     *
     * @param token {string} 用户令牌
     * @param project {Project} 项目信息
     */
    public createProject(token: string ,project: Project): Promise<string>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('project', project?.toString() || '');
        return request.post<string>(this.baseurl + 'createProject', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取项目列表
     *
     * @param token {string} 用户令牌
     * @param teamId {string} 团队Id
     * @param keyWord {string} 关键字
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getProjectList(token: string ,teamId: string, keyWord: string ,startIndex: number ,maxSize: number): Promise<ProjectList>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('teamId', teamId?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<ProjectList>(this.baseurl + 'getProjectList', formData
        ).then(res => {
            return !!res ? new ProjectList(res) : null;
        });
    }

    /**
     * 获取项目信息
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     */
    public getProject(token: string ,projectCode: string): Promise<Project>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        return request.post<Project>(this.baseurl + 'getProject', formData
        ).then(res => {
            return !!res ? new Project(res) : null;
        });
    }

    /**
     * 修改项目信息
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编码
     * @param projectName {string} 项目名称
     * @param description {string} 描述
     * @param language {string} 语言
     * @param databaseType {string} 数据库类型
     * @param codeTemplateGroupId {string} 代码模板组Id
     */
    public modifyProject(token: string ,projectCode: string ,projectName: string ,description: string ,language: string ,databaseType: string ,codeTemplateGroupId: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('projectName', projectName?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('language', language?.toString() || '');
        formData.append('databaseType', databaseType?.toString() || '');
        formData.append('codeTemplateGroupId', codeTemplateGroupId?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyProject', formData
        ).then(res => {
            return res;
        });
    }

    // endregion

}
