/**
 * 项目管理
 *
 */
import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';


export class Project extends BaseDataEntity {
    code: string; // 项目编号
    parentCode: string; // 父项目编号
    name: string; // 项目名称
    description: string; // 项目说明
    teamId: string; // 团队Id
    teamName: string; // 团队名称
    language: string; // 开发语言
    databaseType: string; // 数据库类型
    codeTemplateGroupId: string; // 代码生成模板库Id
    children: ProjectList; // 子项目
    constructor(options: any = {}) {
        super(options);
        this.code = options?.code || '';
        this.parentCode = options?.parentCode || '';
        this.name = options?.name || '';
        this.description = options?.description || '';
        this.teamId = options?.teamId || null;
        this.teamName = options?.teamName || null;
        this.language = options?.language || undefined;
        this.databaseType =  options?.databaseType || undefined;
        this.codeTemplateGroupId = options?.codeTemplateGroupId || undefined;
        this.children = options?.children ? new ProjectList(options.children) : null;
    }
}

export class ProjectList extends DataList<Project> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new Project(item));
            }
        }
    }
}
