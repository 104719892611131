import router from './router';
import store from './store';
import views from './views';

import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue/es';

import 'cmt-vue-lib/style.css';
import type {ICmSoftParams} from 'cmt-vue-lib';
import {CmUI, AbstractLifeCycle, cmSoft, permissionGuard} from 'cmt-vue-lib';

const cmApp = async () => {
    class LifeCycle extends AbstractLifeCycle {
        // 生命周期 - 初始化
        created(ctx: ICmSoftParams): Promise<boolean> {
            console.log('框架加载了', ctx);
            return Promise.resolve(true);
        }

        // 生命周期 - 登录系统
        entered(ctx: ICmSoftParams): Promise<boolean> {
            console.log('登录成功了', ctx);
            return Promise.resolve(true);
        }

        // 生命周期 - 身份过期
        authExpired(ctx: ICmSoftParams): Promise<boolean> {
            console.log('身份过期了', ctx);
            return Promise.resolve(true);
        }

        // 生命周期 - 退出系统
        exited(ctx: ICmSoftParams): Promise<boolean> {
            console.log('退出登录了', ctx);
            return Promise.resolve(true);
        }
    }

    const softInstance = await cmSoft
        .useProjectConfig('./config/project-config.json')
        .useLifeCycle(new LifeCycle())
        .usePlugin(Antd)
        .usePlugin(CmUI)
        .useViews(views)
        .useStore(store)
        .useRouter(router)
        .useGuard(permissionGuard)
        .install();
    console.log('softInstance', softInstance);
};
cmApp().catch(err => window.alert(err.message));
