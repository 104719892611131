import { defineComponent, ref } from 'vue';
import { PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { TableColumnsType } from 'ant-design-vue';

import { ProjectMgeController } from '@/services/project-mge.controller';
import { CodeTemplateGroupController } from '@/services/code-template-group.controller';
import { Project, ProjectList } from '@/domains/project.domain';
import { CodeTemplateGroupList } from '@/domains/code-template-group.domain';
import { CmBaseComponent, DictTypeEnum, DictValueList, SysDictController, Utils } from 'cmt-vue-lib';
import { ProjectTeamController } from '@/services/project-team.controller';
import { ProjectTeamList } from '@/domains/project-team.domain';

export default defineComponent({
    name: 'ProjectManage',
    title: '工程管理',
    components: {
        SearchOutlined,
        RedoOutlined,
        PlusOutlined
    },
    extends: CmBaseComponent,
    props: ['props'],
    setup() {
        const columns = ref<TableColumnsType>([
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                customRender: ({ text, record, index }) => `${index + 1}`,
            },
            {
                key: 'teamName',
                dataIndex: 'teamName',
                align: 'center',
                title: '团队名称',
                width: 200,
                ellipsis: true
            },
            {
                key: 'name',
                dataIndex: 'name',
                align: 'left',
                title: '项目名称',
                width: 300,
                ellipsis: true
            },
            {
                key: 'language',
                dataIndex: 'language',
                width: 200,
                align: 'center',
                title: '开发语言'
            },
            {
                key: 'databaseType',
                dataIndex: 'databaseType',
                width: 200,
                align: 'center',
                title: '数据库类型'
            },
            {
                key: 'description',
                dataIndex: 'description',
                align: 'left',
                title: '项目说明',
                ellipsis: true,
                customRender: (record: any) => record.value || '-'
            },
            {
                title: '更新时间',
                key: 'updateTime',
                dataIndex: 'updateTime',
                align: 'center',
                ellipsis: true,
                width: 150,
                customRender: (record: any) => Utils.dateformat(record.value) || '-'
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 200,
            },
        ]);

        const rules = {
            name: [{ required: true, message: '请输入项目名称!', trigger: ['change', 'blur'] }],
            description: [{ required: false, message: '请输入项目说明!', trigger: ['change', 'blur'] }],
            teamId: [{ required: true, message: '请选择项目团队!', trigger: ['change', 'blur'] }],
            language: [{ required: true, message: '请选择开发语言!', trigger: ['change', 'blur'] }],
            databaseType: [{ required: true, message: '请选择数据库类型!', trigger: ['change', 'blur'] }],
            codeTemplateGroupId: [{ required: true, message: '请选择数据库类型!', trigger: ['change', 'blur'] }],
        };

        return {
            columns,
            rules
        };
    },
    data() {
        return {
            sysDictController: new SysDictController(),
            projectEditState: false,
            projectMgeController: new ProjectMgeController(),
            projectList: new ProjectList(),
            project: new Project(),
            languageDictList: new DictValueList(), // 字典 - 开发语言
            databaseTypeDictList: new DictValueList(), // 字典 - 数据库类型
            codeTemplateGroupController: new CodeTemplateGroupController(),
            codeTemplateGroupList: new CodeTemplateGroupList(),
            formLoading: false,
            teamController: new ProjectTeamController(),
            projectTeams: new ProjectTeamList(),
            teamId: null
        };
    },
    created() {
        this.getProjectTeamList();
        this.getProjectList();
        // 获取开发语言字典
        this.getDictListByLanguageType();
        // 获取数据类型字典
        this.getDictListByDbType();
    },
    methods: {
        getDictListByLanguageType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DEV_LANGUAGE_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.languageDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByDbType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DB_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.databaseTypeDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        openProjectEditTab(item: Project) {
            this.cmSysSvr.openTab('ProjectDetail', item.name, item);
        },
        resetProjectList() {
            this.cmKeyWord = '';
            this.teamId = null;
            this.getProjectList();
        },
        customTableRow(record) {
            return {
                onDblclick: () => {
                    this.openProjectEditTab(record);
                }
            };
        },
        onNewOrEditProjectSubmit() {
            this.$refs.newProjectRef.validate().then(() => {
                if (this.projectEditState) {
                    this.cmModalSvr.confirm({
                        iconType: 'warning',
                        title: '操作提示',
                        content: '您确定要保存编辑后的数据吗？',
                        centered: true,
                        onOk: () => {
                            return new Promise<boolean>((resolve) => {
                                this.projectMgeController.modifyProject(
                                    this.token,
                                    this.project.code,
                                    this.project.name,
                                    this.project.description,
                                    this.project.language,
                                    this.project.databaseType,
                                    this.project.codeTemplateGroupId).then(() => {
                                        this.cmMsgSvr.success('修改项目信息成功');
                                        this.cmModalVisible = false;
                                        this.getProjectList();
                                        resolve(true);
                                    }).catch(err => {
                                        this.cmMsgSvr.error(err.message);
                                        resolve(false);
                                    });
                            });
                        }
                    });
                } else {
                    this.cmButtonLoading = true;
                    this.projectMgeController.createProject(this.token, this.project).then(() => {
                        this.cmMsgSvr.success('新增项目成功');
                        this.cmModalVisible = false;
                        this.project = new Project();
                        this.getProjectList();
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.cmButtonLoading = false;
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        },
        onDeleteProjectSubmit(item: Project) {
            return new Promise((resolve) => {
                this.projectMgeController.deleteProject(this.token, item.code, false).then(() => {
                    this.cmMsgSvr.success('删除项目成功');
                    this.getProjectList();
                    resolve(true);
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                    resolve(false);
                });
            });
        },
        onOpenNewOrEditModel(type: string, item?: Project) {
            if (type === 'add') {
                this.projectEditState = false;
                this.project = new Project();
                this.cmModalVisible = true;
            } else if (type === 'edit') {
                this.projectEditState = true;
                this.project = new Project(item); // todo...
                this.getProject();
                // 打开编辑模态框
                this.cmModalVisible = true;
            }
        },
        onSelectLanguage(language: string) {
            // 切换语言选项 则 模板库变化
            this.project.codeTemplateGroupId = undefined;
            this.codeTemplateGroupList = new CodeTemplateGroupList();
            this.getCodeTemplateGroupList(language);
        },
        getProjectList() {
            this.cmLoadingState = true;
            this.projectMgeController.getProjectList(this.token, this.teamId, this.cmKeyWord, this.cmPageBean.currentPage - 1, this.cmPageBean.pageSize).then((res: ProjectList) => {
                if (res) {
                    this.projectList = res;
                    this.cmPageBean.totalCount = this.projectList.totalCount;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmLoadingState = false;
            });

        },
        getProject() {
            this.formLoading = true;
            this.projectMgeController.getProject(this.token, this.project.code).then((res: Project) => {
                if (res) {
                    this.project = res;
                    // 获取模板库
                    this.getCodeTemplateGroupList(this.project.language);
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.formLoading = false;
            });
        },
        getCodeTemplateGroupList(language: string) {
            // 获取模板库
            this.formLoading = true;
            this.codeTemplateGroupController.getCodeTemplateGroupList(this.token, this.cmKeyWord, language, 0, -1).then(res => {
                if (res) {
                    this.codeTemplateGroupList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.formLoading = false;
            });
        },
        getProjectTeamList() {
            this.teamController.getProjectTeamList(this.token, null, 0, -1).then(res => {
                if (res) {
                    this.projectTeams = res;
                }
            });
        }
    }
});
