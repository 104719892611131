import {request} from 'cmt-vue-lib';
import {ModelDefineTemplateRelation} from '@/domains/model-define-template-relation.domain';

/**
 * 模型模板关联控制器
 * <p>
 * description 用于管理模型与模板关联控制器
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 14:17:50
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ModelDefineTemplateRelationController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'ModelDefineTemplateRelationController.svc/';
    }

    // region 自动生成代码

    /**
     * 设置模型与模板对应关系
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编号
     * @param modelDefineId {string} 模型ID
     * @param codeTemplateIds {string[]} 代码模板ID（多个用逗号（,）隔开）
     */
    public setRelation(token: string ,projectCode: string ,modelDefineId: string ,codeTemplateIds: string[]): Promise<ModelDefineTemplateRelation>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('modelDefineId', modelDefineId?.toString() || '');
        formData.append('codeTemplateIds', JSON.stringify(codeTemplateIds || []));
        return request.post<ModelDefineTemplateRelation>(this.baseurl + 'setRelation', formData
        ).then(res => {
            return !!res ? new ModelDefineTemplateRelation(res) : null;
        });
    }

    /**
     * 根据模型ID获取所有关联
     *
     * @param token {string} 用户令牌
     * @param projectCode {string} 项目编号
     * @param modelDefineId {string} 模型ID
     */
    public getModelDefineTemplateRelationByModelDefineId(token: string ,projectCode: string ,modelDefineId: string): Promise<ModelDefineTemplateRelation>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('projectCode', projectCode?.toString() || '');
        formData.append('modelDefineId', modelDefineId?.toString() || '');
        return request.post<ModelDefineTemplateRelation>(this.baseurl + 'getModelDefineTemplateRelationByModelDefineId', formData
        ).then(res => {
            return !!res ? new ModelDefineTemplateRelation(res) : null;
        });
    }

    // endregion

}
