
import {defineComponent} from 'vue';
import {CmCard, CmCardBody} from 'cmt-vue-lib';
import {createDynamicView} from "@/views/dynamic-views/test/createDynamicView";
import TContainer from "@/views/dynamic-views/test/t-container.vue";

export default defineComponent({
  name: "TCore",
  components: {
    CmCard,
    CmCardBody,
  },
  setup() {
    return {};
  },
  mounted() {
    createDynamicView('#viewInner', 'TContainer', 'TView', {title: '这是动态视图'});
  }
});
