
import {defineComponent, PropType} from 'vue';
import {CmCard, CmCardBody, CmCardFooter} from "cmt-vue-lib";
import {Button} from 'ant-design-vue';

export default defineComponent({
  name: "TContainer",
  components: {
    CmCard,
    CmCardBody,
    CmCardFooter,
    AButton: Button
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: 'container'
    }
  }
});
