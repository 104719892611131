import {createVNode, render, Component, markRaw} from "vue";
import {Utils, sysSvr} from 'cmt-vue-lib';

/**
 * 创建动态视图
 * @param parentContainer 父组件
 * @param viewContainer 放置视图的容器
 * @param dynamicComponent 视图selector
 * @param dynamicParams 传入视图的参数
 */
export function createDynamicView(parentContainer: string | HTMLElement,
                                  viewContainer: string | Component,
                                  dynamicComponent: string | Component,
                                  dynamicParams?: Record<string, any>) {
    // 第一步 处理父容器
    let parent: HTMLElement;
    if (typeof parentContainer === 'string') {
        parent = document.querySelector(parentContainer);
        Utils.assert(!parent, `未找到ID为[${parent}]的父容器`);
    } else {
        parent = parentContainer;
    }
    Utils.assert(!parent, '未找到可用的父容器');

    // 第二步 处理视图容器
    let container: Component;
    if (typeof viewContainer === 'string') {
        const findComponentType = sysSvr.appComponents[viewContainer];
        Utils.assert(!findComponentType, `${viewContainer}不存在,请检查是否全局注册`);
        container = Utils.deepClone<Component>(findComponentType);
    } else {
        container = viewContainer;
    }
    Utils.assert(!container, `viewContainer参数无效`);

    // 第三步 处理动态视图/动态组件
    let dynamicView: Component;
    if (typeof dynamicComponent === 'string') {
        const findComponentType = sysSvr.appComponents[dynamicComponent];
        Utils.assert(!findComponentType, `${dynamicComponent}不存在,请检查是否全局注册`);
        dynamicView = Utils.deepClone<Component>(findComponentType);
    } else {
        dynamicView = dynamicComponent;
    }
    Utils.assert(!dynamicView, `dynamicComponent参数无效`);

    const vContainer = createVNode(container, {}, {
        dynamic: () => createVNode(dynamicView, dynamicParams)
    });

    render(vContainer, parent);
}