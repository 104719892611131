import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, { autocomplete: "off" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: "元素备注",
        name: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_textarea, {
            "allow-clear": "",
            value: _ctx.documentation,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentation) = $event)),
            size: "small",
            rows: 2,
            onBlur: _ctx.updateDocumentation
          }, null, 8, ["value", "onBlur"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}