import {defineComponent} from 'vue';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    RedoOutlined,
    SearchOutlined,
    UploadOutlined,
} from '@ant-design/icons-vue';
import {CodeTemplateGroupController} from '@/services/code-template-group.controller';
import {CodeTemplateController} from '@/services/code-template.controller';
import {CodeTemplateGroup, CodeTemplateGroupList} from '@/domains/code-template-group.domain';
import {CodeTemplate, CodeTemplateList} from '@/domains/code-template.domain';
import {ModelDefineTypeEnum} from '@/enums/model-define-type.enum';
import {
    CmBaseComponent,
    DictValueList,
    DictTypeEnum,
    BaseDataEntity,
    decodeByBase64,
    Attachment,
    SysDictController,
    Utils,
    msgSvr
} from 'cmt-vue-lib';

import {VAceEditor} from 'vue3-ace-editor';
// import "ace-builds/webpack-resolver"; // webpack环境下需要引入
import 'ace-builds/src-noconflict/mode-ftl';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/ftl';

export default defineComponent({
    name: 'ProjectTemplateLibrary',
    title: '工程模板库',
    components: {PlusOutlined, UploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined, RedoOutlined, VAceEditor},
    extends: CmBaseComponent,
    props: ['props'],
    setup() {
        const columns = [
            {
                key: 'index',
                dataIndex: 'index',
                align: 'center',
                title: '序号',
                width: 50,
                customRender: ({index}) => `${index + 1}`,
            },
            {
                key: 'name',
                dataIndex: 'name',
                align: 'center',
                title: '模板名称',
                ellipsis: true
            },
            {
                key: 'templateType',
                dataIndex: 'templateType',
                width: 300,
                align: 'center',
                title: '模板类型',
                ellipsis: true
            },
            {
                key: 'version',
                dataIndex: 'version',
                align: 'center',
                title: '模板版本',
                ellipsis: true
            },
            {
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                title: '模板状态',
                ellipsis: true
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 300,
            }
        ];
        const rules = {
            name: [{required: true, message: '请输入模板名称!', trigger: ['change', 'blur']}],
            language: [{required: true, message: '请选择开发语言!', trigger: ['change', 'blur']}],
            templateType: [{required: true, message: '请输入模板类型!', trigger: ['change', 'blur']}],
            modelType: [{required: true, message: '请输入模型类型!', trigger: ['change', 'blur']}],
            status: [{required: true, message: '请输入模板状态!', trigger: ['change', 'blur']}],
            version: [{required: true, message: '请输入模板版本!', trigger: ['change', 'blur']}],
            content: [{required: true, message: '请上传模板文件!', trigger: ['change', 'blur']}],
            extension: [{required: true, message: '请选择文件扩展名称格式!', trigger: ['change', 'blur']}]
        };
        return {columns, rules};
    },
    data() {
        return {
            cmCodeEditVisible: false, // 代码编辑器模态框 - 状态
            cmModelTemplateVisible: false, // 添加或编辑模板库的模态框 - 状态
            cmMcmModelTempVisible: false, // 新增或编辑模板的模态框 - 状态
            cmTableLoading: false,
            cmMenuLoading: false,
            selectFirst: [], // 默认选中菜单第一项
            codeTemplateGroupId: '', // 当前选中的模板库id

            codeTemplateGroupController: new CodeTemplateGroupController(),
            codeTemplateController: new CodeTemplateController(),
            sysDictController: new SysDictController(),
            codeTemplate: new CodeTemplate(),
            codeTemplateGroup: new CodeTemplateGroup(),
            codeTemplateList: new CodeTemplateList(),
            codeTemplateGroupList: new CodeTemplateGroupList(),

            languageDictList: new DictValueList(), // 字典 - 开发语言
            templateTypeList: new DictValueList(), // 字典 - 模板类型
            extensionTypeList: new DictValueList(), // 字典 - 文件后缀类型
            modelDefineTypeEnum: Utils.enumToMap(ModelDefineTypeEnum),
            selectCodeTemplate: undefined as CodeTemplate | undefined,
        };
    },

    created() {
        // 获取模板库列表
        this.getCodeTemplateGroupList();
        // 获取开发语言字典
        this.getDictListByLanguageType();
        // 获取代码模板类型字典
        this.getDictListByCodeTemplateType();
        // 获取模板文件后缀类型字典
        this.getDictListByFileExtensionType();
    },
    methods: {
        getDictListByLanguageType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DEV_LANGUAGE_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.languageDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByCodeTemplateType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.CODE_TEMPLATE_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.templateTypeList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByFileExtensionType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.CODE_TEMPLATE_FILE_EXTENSION_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.extensionTypeList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },

        // 初始化新增或编辑模板库 - 状态
        onAddOrEditTemplateGroup(item?: CodeTemplateGroup) {
            this.codeTemplateGroup = !item ? new CodeTemplateGroup() : new CodeTemplateGroup(item);
            this.cmModelTemplateVisible = true;
        },
        onSaveMenuModel() {
            this.$refs.addTemplateGroup.validate().then(() => { // 菜单对应模态框表单验证
                this.cmModalSvr.confirm({
                    iconType: 'warning',
                    title: '操作提示',
                    content: '您确定要保存吗？',
                    onOk: () => {
                        !this.codeTemplateGroup.id ? this.addCodeTemplateGroup() : this.modifyCodeTemplateGroup();
                        this.cmModelTemplateVisible = false;
                    },
                });
            }).catch(err => {
                // console.log(err);
            });
        },
        // 初始化新增或编辑模板 - 状态
        newAddOrEditTemplate(record?: CodeTemplate) {
            // this.codeTemplate = !record ? new CodeTemplate() : new CodeTemplate(record);
            if (!record) {
                this.codeTemplate = new CodeTemplate();
            } else {
                this.codeTemplate = new CodeTemplate(record);
                this.codeTemplate.content = decodeByBase64(record.templateContent.content); // 处理表单上传文件校验 - upload
            }
            this.cmMcmModelTempVisible = true;
        },
        onSaveTableModel() {
            this.$refs.addOrEditTemplate.validate().then(() => { // table对应的模态框表单验证
                if (this.codeTemplate.id) {
                    this.cmModalSvr.confirm({
                        iconType: 'warning',
                        title: '操作提示',
                        content: '您确定要保存吗？',
                        onOk: () => {
                            return new Promise<boolean>((resolve) => {
                                if (typeof this.codeTemplate.templateContent.content === 'string') {
                                    this.codeTemplate.templateContent.content = decodeByBase64(this.codeTemplate.templateContent.content);
                                    this.codeTemplate.templateContent.content = new File([new Blob([this.codeTemplate.templateContent.content], {type: 'text/plain'})], this.codeTemplate.templateContent.name);
                                }
                                // 编辑模板
                                this.codeTemplateController.modifyCodeTemplate(this.token, this.codeTemplate.id, this.codeTemplate.name, this.codeTemplateGroupId, this.codeTemplate.templateType, this.codeTemplate.modelType, this.codeTemplate.prefix, this.codeTemplate.suffix, this.codeTemplate.extension, this.codeTemplate.version, this.codeTemplate.templateContent?.content, '').then(() => {
                                    this.cmMsgSvr.success('编辑模板成功');
                                    this.cmMcmModelTempVisible = false;
                                    this.getCodeTemplateList();
                                    resolve(true);
                                }).catch(err => {
                                    this.cmMsgSvr.error(err.message);
                                    resolve(false);
                                });
                            });
                        },
                    });
                } else { // 添加模板
                    this.cmButtonLoading = true;
                    this.codeTemplateController.addCodeTemplate(this.token, this.codeTemplate.name, this.codeTemplateGroupId, this.codeTemplate.templateType, this.codeTemplate.modelType, this.codeTemplate.prefix, this.codeTemplate.suffix, this.codeTemplate.extension, this.codeTemplate.version, this.codeTemplate.templateContent?.content).then(res => {
                        if (res) {
                            this.cmMsgSvr.success('新增模板成功');
                            this.cmMcmModelTempVisible = false;
                            this.getCodeTemplateList();
                        }
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    }).finally(() => {
                        this.cmButtonLoading = false;
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 菜单选中当前项
        onSelectTemplateGroup({key}) {
            this.codeTemplateGroupId = key;
            this.getCodeTemplateList();
        },
        // 重置查询
        resetTemplate() {
            this.cmPageBean.currentPage = 1;
            this.cmKeyWord = '';
            this.getCodeTemplateList();
        },
        // 处理上传文件名称删除事件
        handleDeleteUpload() {
            this.codeTemplate.templateContent = new Attachment();
            this.codeTemplate.content = null; // 处理表单文件上传 - upload
        },
        // 代码编辑器
        onOpenEditTemplate(record?: CodeTemplate) {
            this.codeTemplate = new CodeTemplate(record);
            this.codeTemplate.templateContent.content = decodeByBase64(record.templateContent.content); // 解码
            this.cmCodeEditVisible = true;
        },
        onSaveTemplateEdit() {
            const file = new File([new Blob([this.codeTemplate.templateContent.content], {type: 'text/plain'})], this.codeTemplate.templateContent.name);
            this.cmModalSvr.confirm({
                iconType: 'warning',
                title: '操作提示',
                content: '您确定要保存吗？',
                onOk: () => {
                    return new Promise<boolean>((resolve) => {
                        this.codeTemplateController.setTemplateAttachment(this.token, this.codeTemplate.id, file).then(() => {
                            this.cmMsgSvr.success('编辑成功');
                            this.cmCodeEditVisible = false;
                            this.getCodeTemplateList();
                            resolve(true);
                        }).catch(err => {
                            this.cmModalSvr.error(err.message);
                            resolve(false);
                        });
                    });
                }
            });
        },
        // 获取模板库数据列表
        getCodeTemplateGroupList() {
            this.cmMenuLoading = true;
            this.codeTemplateGroupController.getCodeTemplateGroupList(this.token, this.cmKeyWord, null, 0, -1).then(res => {
                if (res) {
                    this.codeTemplateGroupList = res;
                    if (this.selectFirst.length <= 0) {
                        this.codeTemplateGroupId = this.codeTemplateGroupList.first.id;
                        this.selectFirst = [this.codeTemplateGroupId];
                    }
                    this.getCodeTemplateList();
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmMenuLoading = false;
            });
        },
        // 获取模板列表
        getCodeTemplateList() {
            this.cmTableLoading = true;
            this.codeTemplateController.getCodeTemplateList(this.token,
                this.cmKeyWord,
                this.codeTemplateGroupId,
                null,
                this.cmPageBean.currentPage - 1,
                this.cmPageBean.pageSize).then(res => {
                if (res) {
                    this.codeTemplateList = res;
                    this.cmPageBean.totalCount = this.codeTemplateList.totalCount;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmTableLoading = false;
            });
        },
        // 添加模板库
        addCodeTemplateGroup() {
            this.codeTemplateGroupController.addCodeTemplateGroup(this.token, this.codeTemplateGroup.name, this.codeTemplateGroup.version, this.codeTemplateGroup.language).then(res => {
                if (res) {
                    this.cmMsgSvr.success('添加模板库成功');
                    this.getCodeTemplateGroupList();
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        // 删除模板库
        deleteCodeTemplateGroup(id) {
            this.codeTemplateGroupController.deleteCodeTemplateGroup(this.token, id, false).then(() => {
                this.cmMsgSvr.success('删除模板库成功');
                if (id === this.selectFirst[0]) {
                    this.selectFirst = [];
                }
                this.getCodeTemplateGroupList();
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        // 删除模板
        deleteCodeTemplate(id) {
            this.codeTemplateController.deleteCodeTemplate(this.token, id).then(() => {
                this.cmMsgSvr.success('删除模板成功');
                this.getCodeTemplateList();
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        // 编辑模板库
        modifyCodeTemplateGroup() {
            this.codeTemplateGroupController.modifyCodeTemplateGroup(this.token, this.codeTemplateGroupId, this.codeTemplateGroup.name, this.codeTemplateGroup.version, this.codeTemplateGroup.language, '').then(() => {
                this.cmMsgSvr.success('编辑模板库成功');
                this.getCodeTemplateGroupList();
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        // 切换模板状态
        onChangeTemplateStatus(record) {
            this.cmModalSvr.confirm({
                iconType: 'warning',
                title: '操作提示',
                content: '您确定要改变模板状态吗？',
                onOk: () => {
                    this.codeTemplateController.setCodeTemplateStatus(this.token, record.id, record.status).then(() => {
                        let text = '启用';
                        if (!record.status) text = '停用';
                        this.cmMsgSvr.success('模板' + text + '成功');
                        this.getCodeTemplateList();
                    }).catch(err => {
                        this.cmMsgSvr.error(err.message);
                    });
                },
                onCancel: () => {
                    this.getCodeTemplateList();
                }
            });
        },

        // 上传文件方法重写
        onFileBeforeUpload(file) {
            // 文件上传之前的一些处理
            const fileExtension = file.name.substring(file.name.lastIndexOf("."));
            if (fileExtension !== '.ftl') {
                this.cmMsgSvr.warn(`上传文件类型[${fileExtension}]不符合规定文件类型[ftl]`);
                return false;
            }
        },
        customRequestFunc2(options) {
            this.codeTemplate.templateContent.content = options.file;
        },
        customRequestFunc(options) {
            if (this.selectCodeTemplate instanceof BaseDataEntity) {
                this.codeTemplateController.setTemplateAttachment(this.token, this.selectCodeTemplate.id, options.file).then(() => {
                    this.cmMsgSvr.success('文件上传成功');
                    this.getCodeTemplateList();
                }).catch(err => {
                    this.cmMsgSvr.error(err.message);
                }).finally(() => {
                    this.selectCodeTemplate = undefined;
                });
            }
        },
    }
});
