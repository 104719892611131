
import {defineComponent, ref, h} from 'vue';
import {Utils, modalSvr} from 'cmt-vue-lib';

export default defineComponent({
  name: "DesignerTools",
  props: ['designerInstance'],
  setup() {
    const refFile = ref<any>();

    const onOpenFile = () => {
      refFile.value.value = null;
      refFile.value.click();
    };

    return {
      onOpenFile,
      refFile
    };
  },
  data() {
    return {
      canRedo: false,
      canUndo: false,
      isSimulation: false,
      isMiniMap: false,
      isLint: false,
      isFullScreen: false,
      defaultZoom: 1,

      previewModelVisible: false,
      previewResult: '',
    };
  },
  created() {
    this.initModels();
  },
  methods: {
    initModels() {
      if (!this.designerInstance) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.initModels(), 10);
        return;
      }
      if (this.timer) clearTimeout(this.timer);

      // 初始化流程模型监听器
      this.initModelListeners();
    },
    initModelListeners() {
      const eventBus = this.designerInstance.get("eventBus");
      // 监听图形改变
      eventBus.on("commandStack.changed", event => {
        this.canRedo = this.designerInstance.get("commandStack").canRedo();
        this.canUndo = this.designerInstance.get("commandStack").canUndo();
      });

      // 监听校验开关
      eventBus.on("linting.toggle", ({active}) => {
        this.isLint = active;
      });

      // 监听模拟开关
      eventBus.on("tokenSimulation.toggleMode", ({active}) => {
        this.isSimulation = active;
      });

      // 监听视图缩放变化
      this.designerInstance.on("canvas.viewbox.changed", ({viewbox}) => {
        const {scale} = viewbox;
        this.defaultZoom = Math.floor(scale * 100) / 100;
      });

    },
    /* 当打开选择的文件 */
    onLocalFile() {
      const file = this.refFile.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const xmlStr = e.target.result;
        if (xmlStr) {
          this.$emit('designerRestart', xmlStr.toString());
        }
      };
    },
    /* 流程校验 */
    lint() {
      this.designerInstance.get("linting").toggle();
    },
    /* 流程模拟 */
    simulation() {
      this.designerInstance.get("toggleMode").toggleMode();
    },
    /* 打开小地图 */
    toggleMiniMap() {
      this.isMiniMap = !this.isMiniMap;
      this.designerInstance.get("minimap").toggle();
    },
    /* 打开键盘快捷键帮助 */
    openKeyboardHelp() {
      modalSvr.info({
        title: '快捷键：',
        centered: true,
        content: h('ol', {}, [
          h('li', '撤销 -> ctrl + Z'),
          h('li', '前进 -> ctrl + ⇧ + Z'),
          h('li', '选择全部 -> ctrl + A'),
          h('li', '复制 -> ctrl + C'),
          h('li', '粘贴 -> ctrl + V'),
          h('li', '滚动(垂直) -> ctrl + Scrolling'),
          h('li', '滚动(水平) -> ctrl + ⇧ + Scrolling'),
          h('li', '编辑 -> E'),
          h('li', '抓手工具 -> H'),
          h('li', '套索工具 -> L'),
          h('li', '空间工具 -> S'),
          h('li', '删除选中 -> Del'),
        ])
      });
    },
    /* 保存模型 */
    saveModel() {
      this.designerInstance.saveXML().then(({xml}) => {
        this.$emit('saveModel', xml);
      });
      // todo... 出错应抛出去
    },
    /* 前进 */
    redo() {
      if (this.canRedo) {
        this.designerInstance.get("commandStack").redo();
      }
    },
    /* 后退 */
    undo() {
      if (this.canUndo) {
        this.designerInstance.get("commandStack").undo();
      }
    },
    /* 重新绘制 */
    restart() {
      this.$emit('designerRestart');
    },
    /* 放大视图 */
    zoomIn(zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100;
      if (newZoom <= 4) {
        this.defaultZoom = newZoom;
        this.designerInstance.get("canvas").zoom(this.defaultZoom);
      }
    },
    /* 缩小视图 */
    zoomOut(zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100;
      if (newZoom >= 0.2) {
        this.defaultZoom = newZoom;
        this.designerInstance.get("canvas").zoom(this.defaultZoom);
      }
    },
    /* 垂直视图并居中 */
    reZoom() {
      this.defaultZoom = 1;
      this.designerInstance.get("canvas").zoom("fit-viewport", "auto");
    },
    /* 下载文件总处理方法 */
    downloadDesigner(type: string) {
      switch (type) {
        case 'xml':
          this.designerInstance.saveXML().then(({xml}) => {
            const href = Utils.downloadFormatUrl(xml);
            Utils.downloadByHref(href, new Date().getTime().toString() + '.' + type);
          });
          break;
        case 'svg':
          this.designerInstance.saveSVG().then(({svg}) => {
            const href = Utils.downloadFormatUrl(svg);
            Utils.downloadByHref(href, new Date().getTime().toString() + '.' + type);
          });
          break;
      }
    },
    /* 当下载被选择 */
    onDownloadSelect({key}) {
      switch (key) {
        case 'download_xml':
          this.downloadDesigner('xml');
          break;
        case 'download_svg':
          this.downloadDesigner('svg');
          break;
      }
    },
    /* 当预览被选择 */
    onPreviewSelect({key}) {
      switch (key) {
        case 'preview_xml':
          this.designerInstance.saveXML({format: true}).then(({xml}) => {
            this.previewResult = xml;
            this.previewModelVisible = true;
          });
          break;
      }
    },

  },
})
;
