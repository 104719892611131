import {request} from 'cmt-vue-lib';
import {ProjectTeam, ProjectTeamList} from '@/domains/project-team.domain';
import {TeamMemberList} from '@/domains/team-member.domain';

/**
 * 项目团队管理 Controller
 * <p>
 * description 流程模板控制器定义
 *
 * @author 超管理员(admin)
 * @date 2022-05-25 11:23:08
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProjectTeamController {
    private baseurl: string;

    constructor() {
        this.baseurl = 'ProjectTeamController.svc/';
    }

    // region 自动生成代码

    /**
     * 添加项目团队
     *
     * @param token {string}       用户token
     * @param name {string}        团队名称
     * @param description {string} 团队说明
     * @param avatar {string}      团队图标
     * @param remark {string}      备注
     */
    public addProjectTeam(token: string, name: string, description: string, avatar: string, remark: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('avatar', avatar?.toString() || '');
        formData.append('remark', remark?.toString() || '');
        return request.post<void>(this.baseurl + 'addProjectTeam', formData).then(res => {
            return res;
        });
    }

    /**
     * 修改项目团队
     *
     * @param token{string}       用户token
     * @param id{string}          团队Id
     * @param name{string}        团队名称
     * @param description{string} 团队说明
     * @param avatar{string}      团队图标
     * @param remark{string}      备注
     */
    public modifyProjectTeam(token: string, id: string, name: string, description: string, avatar: string, remark: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('description', description?.toString() || '');
        formData.append('avatar', avatar?.toString() || '');
        formData.append('remark', remark?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyProjectTeam', formData).then(res => {
            return res;
        });
    }

    /**
     * 修改项目团队
     *
     * @param token{string}       用户token
     * @param id{string}          团队Id
     * @param teamMemberId{string}        团队成员Id
     */
    public setProjectTeamAdmin(token: string, id: string, teamMemberId: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('teamMemberId', teamMemberId?.toString() || '');
        return request.post<void>(this.baseurl + 'setProjectTeamAdmin', formData).then(res => {
            return res;
        });
    }

    /**
     * 删除项目团队
     *
     * @param token{string}       用户token
     * @param id{string}          团队Id
     */
    public deleteProjectTeam(token: string, id: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteProjectTeam', formData).then(res => {
            return res;
        });
    }

    /**
     * 删除项目团队
     *
     * @param token{string}       用户token
     * @param id{string}          团队Id
     */
    public getProjectTeam(token: string, id: string): Promise<ProjectTeam> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<ProjectTeam>(this.baseurl + 'getProjectTeam', formData).then(res => {
            return !!res ? new ProjectTeam(res) : null;
        });
    }

    /**
     * 获取项目团队列表
     *
     * @param token{string}      用户token
     * @param keyWord{string}    关键字
     * @param startIndex{number} 开始索引
     * @param maxSize{number}    最大返回数
     */
    public getProjectTeamList(token: string, keyWord: string, startIndex: number, maxSize: number): Promise<ProjectTeamList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<ProjectTeamList>(this.baseurl + 'getProjectTeamList', formData).then(res => {
            return !!res ? new ProjectTeamList(res) : null;
        });
    }

    /**
     * 添加项目团队成员
     *
     * @param token{string}  用户token
     * @param teamId{string} 团队Id
     * @param userId{string} 团队成员ID
     * @param remark{string} 备注
     */
    public addTeamMember(token: string, teamId: string, userId: string, remark: string): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('teamId', teamId?.toString() || '');
        formData.append('userId', userId?.toString() || '');
        formData.append('remark', remark?.toString() || '');
        return request.post<void>(this.baseurl + 'addTeamMember', formData).then(res => {
            return res;
        });
    }

    /**
     * 添加项目团队成员
     *
     * @param token{string}  用户token
     * @param ids{string} 团队Id
     */
    public deleteTeamMember(token: string, ids: string[]): Promise<void> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('ids', JSON.stringify(ids || []));
        return request.post<void>(this.baseurl + 'addTeamMember', formData).then(res => {
            return res;
        });
    }

    /**
     * 获取项目团队列表
     *
     * @param token{string}      用户token
     * @param teamId{string}    团队Id
     */
    public getTeamMemberListByTeamId(token: string, teamId: string): Promise<TeamMemberList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('teamId', teamId?.toString() || '');
        return request.post<TeamMemberList>(this.baseurl + 'getProjectTeamList', formData).then(res => {
            return !!res ? new TeamMemberList(res) : null;
        });
    }

    // endregion

}
