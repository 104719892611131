
import {defineComponent, ref, inject} from 'vue';
import type {TableColumnsType} from 'ant-design-vue';
import {Listener, ListenerList} from "./domains/listener.domain";
import {Field, FieldList} from "./domains/field.domain";
import {ListenerTypeEnum} from "./enums/listener-type.enum";
import {EventTypeEnum} from "./enums/event-type.enum";
import {ScriptTypeEnum} from "./enums/script-type.enum";
import {FieldTypeEnum} from "./enums/field-type.enum";

import {Utils, CmBaseComponent, msgSvr} from "cmt-vue-lib";

export default defineComponent({
  name: "ElementListener",
  props: ['activeElement'],
  extends: CmBaseComponent,
  setup() {
    const size = inject('size', 'small');
    const prefix = inject('prefix', 'camunda');

    const columns = ref<TableColumnsType>([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        customRender: ({index}) => `${index + 1}`,
      },
      {
        key: 'eventType',
        dataIndex: 'eventType',
        align: 'center',
        title: '事件类型',
        ellipsis: true,
        width: 70,
      },
      {
        key: 'listenType',
        dataIndex: 'listenType',
        align: 'center',
        title: '监听器类型',
        ellipsis: true,
        width: 70,
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
      },
    ]);
    const rules = {
      eventType: [{required: true, message: '请输入事件类型!', trigger: ['change', 'blur']}],
      listenType: [{required: true, message: '请输入监听器类型!', trigger: ['change', 'blur']}],
      typeValue: [{required: true, message: '请输入类型值!', trigger: ['change', 'blur']}],
    };

    const columns2 = ref<TableColumnsType>([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        customRender: ({index}) => `${index + 1}`,
      },
      {
        key: 'fieldName',
        dataIndex: 'fieldName',
        align: 'center',
        title: '字段名称',
        ellipsis: true,
      },
      {
        key: 'fieldType',
        dataIndex: 'fieldType',
        align: 'center',
        title: '字段类型',
        ellipsis: true,
      },
      {
        key: 'expressOrValue',
        dataIndex: 'expressOrValue',
        align: 'center',
        title: '字段值/表达式',
        ellipsis: true,
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
      },
    ]);
    const rules2 = {
      eventType: [{required: true, message: '请选择事件类型!', trigger: ['change', 'blur']}],
      listenType: [{required: true, message: '请选择监听器类型!', trigger: ['change', 'blur']}],
      scriptFormat: [{required: true, message: '请输入脚本格式!', trigger: ['change', 'blur']}],
      scriptType: [{required: true, message: '请选择脚本类型!', trigger: ['change', 'blur']}],
      javaType: [{required: true, message: '请输入Java类!', trigger: ['change', 'blur']}],
      expression: [{required: true, message: '请输入表达式!', trigger: ['change', 'blur']}],
      proxyExpression: [{required: true, message: '请输入代理表达式!', trigger: ['change', 'blur']}],
    };

    const rules3 = {
      fieldName: [{required: true, message: '请输入事件类型!', trigger: ['change', 'blur']}],
      fieldType: [{required: true, message: '请输入监听器类型!', trigger: ['change', 'blur']}],
      fieldValue: [{required: true, message: '请输入字段值!', trigger: ['change', 'blur']}],
      fieldExpression: [{required: true, message: '请输入表达式!', trigger: ['change', 'blur']}],
    };
    return {
      size,
      prefix,
      columns,
      rules,

      columns2,
      rules2,
      rules3
    };
  },
  data() {
    return {
      showDrawer: false,
      cmModelFieldVisible: false,
      listenerStatus: false,
      fieldStatus: false,
      fieldCache: {}, // 当前要修改的字段信息
      listenerCache: {}, // 当前要修改的监听器信息

      listener: new Listener(),
      listenerList: new ListenerList(),
      field: new Field(),
      fieldList: new FieldList(),

      listenerTypeEnum: Utils.enumToMap(ListenerTypeEnum),
      eventTypeEnum: Utils.enumToMap(EventTypeEnum),
      scriptTypeEnum: Utils.enumToMap(ScriptTypeEnum),
      fieldTypeEnum: Utils.enumToMap(FieldTypeEnum),
    };
  },
  watch: {
    'activeElement.id': {
      handler(val: string) {
        val && val.length && console.log(val);
      },
      immediate: true
    }
  },

  methods: {
    onOpenNewOrEditModel(record) { // 打开监听器抽屉
      if (record) {
        this.listener = new Listener(record);
        this.listenerCache = record;
        this.listenerStatus = true;
      } else {
        this.listener = new Listener();
        this.field = new Field();
        this.listenerStatus = false;
      }
      this.showDrawer = true;
    },

    onCloseNewOrEditModel() { // 监听器取消按钮
      this.showDrawer = false;
    },

    onSaveNewOrEditModel() { // 保存监听器信息
      this.$refs.listenerForm.validate().then(() => {
        this.cmModalSvr.confirm({
          iconType: 'warning',
          title: '操作提示',
          content: '您确定要保存吗？',
          onOk: () => {
            const listeners = JSON.parse(JSON.stringify(this.listener));
            if (!this.listenerStatus) { // 添加监听器
              this.listenerList.items.push(listeners);
            } else { // 编辑监听器
              const index = this.listenerList.items.findIndex(item => item.eventType === this.listenerCache.eventType);
              if (index >= 0) {
                this.listenerList.items[index] = Object.assign(listeners, {});
              }
            }
            this.showDrawer = false;
          },
        });
      });
    },

    deleteCurryListener(item) { // 删除当前监听器
      const index = this.listenerList.items.findIndex(i => i.name === item.name);
      if (index >= 0) {
        this.listenerList.items.splice(index, 1);
      }
    },

    onOpenNewModel(record) { // 打开添加字段模态框 - 初始化
      if (record) {
        this.field = new Field(record);
        this.fieldCache = record;
        this.fieldStatus = true;
      } else {
        this.fieldStatus = false;
        this.field = new Field();
      }
      this.cmModelFieldVisible = true;
    },

    onSaveTableModel() { // 保存添加字段模态框
      this.$refs.fieldForm.validate().then(() => {
        this.cmModalSvr.confirm({
          iconType: 'warning',
          title: '操作提示',
          content: '您确定要保存吗？',
          onOk: () => {
            const fields = JSON.parse(JSON.stringify(this.field));
            if (!this.fieldStatus) { // 添加字段信息
              if (this.fieldList.some(item => item.fieldName === this.field.fieldName)) {
                return msgSvr.error('属性名不能重复');
              }
              this.fieldList.items.push(fields);
            } else { // 编辑字段信息
              const index = this.fieldList.items.findIndex(item => item.fieldName === this.fieldCache.fieldName);
              if (index >= 0) {
                this.fieldList.items[index] = Object.assign(fields, {});
              }
            }
            this.cmModelFieldVisible = false;
          },
        });
      });
    },

    onDeleteField(item) { // 删除字段信息
      const index = this.fieldList.items.findIndex(i => i.name === item.name);
      if (index >= 0) {
        this.fieldList.items.splice(index, 1);
      }
    }
  }
});
