
import { CmBaseComponent } from 'cmt-vue-lib';
import { defineComponent } from 'vue';
import { DatabaseRepositoryController } from '@/services/database-repository.controller';
import { DatabaseController } from '@/services/database.controller';
import { DatabaseRepositoryList } from '@/domains/database-repository.domain';
import { DatabaseOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { TableInfoList } from '@/domains/table-info.domain';

export default defineComponent({
  name: 'ModalDatabase',
  extends: CmBaseComponent,
  components: {
    DatabaseOutlined,
    SearchOutlined
  },
  data() {
    return {
      databaseRepositoryController: new DatabaseRepositoryController(),
      databaseController: new DatabaseController(),
      databaseRepositoryList: new DatabaseRepositoryList(),
      selectDatabaseRepositoryId: undefined,
      isConnectDatabase: false,
      queryTableLoading: false,
      tableInfos: new TableInfoList(),
      columns: [
        {
          title: "序号",
          dataIndex: 'index',
          align: 'center',
          width: 50,
        },
        {
          title: '表名称',
          dataIndex: 'name',
          align: 'left',
          ellipsis: true
        },
        {
          dataIndex: 'description',
          align: 'left',
          title: '表说明',
          ellipsis: true
        },
      ],
      selectedTableNames: [],
    };
  },
  created() {
    this.selectDatabaseRepositoryId = undefined;
    this.isConnectDatabase = false;
    this.tableInfos = new TableInfoList();
    this.selectedTableNames = [];
    this.getDatabaseRepositoryList();
  },
  methods: {
    handleSelectDatabaseRepositoryChange() {
      this.isConnectDatabase = false;
      this.tableInfos = new TableInfoList();
      this.selectedTableNames = [];
    },
    getDatabaseRepositoryList() {
      this.cmLoadingState = true;
      this.databaseRepositoryController.getDatabaseRepositoryList(this.token, '', 0, -1).then(res => {
        if (res) {
          this.databaseRepositoryList = res;
        }
      }).catch(err => {
        this.cmMsgSvr.error(err.message);
      }).finally(() => {
        this.cmLoadingState = false;
      });
    },
    getTableInfos() {
      this.queryTableLoading = true;
      this.databaseController.getTableInfoList(this.token, this.selectDatabaseRepositoryId, this.cmKeyWord).then(res => {
        if (res) {
          this.tableInfos = res;
          console.log(res);

        }
      }).catch(err => {
        this.cmMsgSvr.error(err.message);
      }).finally(() => {
        this.queryTableLoading = false;
      });
    },
    handleTestConnect() {
      this.cmButtonLoading = true;
      this.databaseController.testConnection(this.token, this.selectDatabaseRepositoryId).then(res => {
        this.isConnectDatabase = res;
        if (res) {
          this.cmMsgSvr.success('测试连接数据库通过');
        } else {
          this.cmMsgSvr.error('测试连接数据库失败');
        }
      }).catch(err => {
        this.cmMsgSvr.error(err.message);
      }).finally(() => {
        this.cmButtonLoading = false;
      });
    },
    handleSelectedRowKeysChange(keys: string[]) {
      this.selectedTableNames = keys;
    },
  }
});

