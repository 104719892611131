import { ModelDefineItemList } from '@/domains/model-define-item.domain';
import { InterfaceDefineItemList } from '@/domains/interface-define-item.domain';
import { BaseDataEntity } from 'cmt-vue-lib';
import { DataList } from 'cmt-vue-lib';

/**
 * 模型定义
 *
 */
export class ModelDefine extends BaseDataEntity {
    id: string; // 模型Id
    projectCode: string; // 项目编号
    index: number; // 索引
    name: string; // 模型名称
    modelType: number; // 模型类型（ModelDefineTypeEnum）
    description: string; // 项目说明
    clazzName: string; // 类名称
    packageName: string; // 包名
    parentClazzName: string; // 基类
    tableName: string; // 数据表名称
    dbExtends: boolean; // 是否数据继承
    modelDefineItems: ModelDefineItemList; // 模型定义项列表
    interfaceDefineItems: InterfaceDefineItemList; // 接口定义项列表
    generateDbModel: boolean; // 是否生成数据库模型
    dbDefineUpperCase: boolean; // 数据库模型列名转大写
    constructor(options: any = {}) {
        super(options);
        this.id = options?.id || '';
        this.projectCode = options?.projectCode || '';
        this.index = options?.index || 0;
        this.name = options?.name || '';
        this.modelType = options?.modelType || 0;
        this.description = options?.description || '';
        this.clazzName = options?.clazzName || '';
        this.packageName = options?.packageName || '';
        this.parentClazzName = options?.parentClazzName || undefined;
        this.tableName = options?.tableName || '';
        this.dbExtends = options?.dbExtends || false;
        this.modelDefineItems = new ModelDefineItemList(options?.modelDefineItems || {});
        this.interfaceDefineItems = new InterfaceDefineItemList(options?.interfaceDefineItems || {});
        this.generateDbModel = options?.generateDbModel || true;
        this.dbDefineUpperCase = options?.dbDefineUpperCase || false;
    }
}

export class ModelDefineList extends DataList<ModelDefine> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ModelDefine(item));
            }
        }
    }
}
