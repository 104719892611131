import {createWebHashHistory, RouteRecordRaw} from 'vue-router';

import {CmLogin, CmNotFoundException, CmPageContainer} from 'cmt-vue-lib';

import RomeHome from '@/views/RoleHome.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'CmPageContainer',
        component: CmPageContainer,
        meta: {
            whiteList: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: CmLogin,
        meta: {
            whiteList: true
        }
    },
    {
        path: '/test',
        name: 'RomeHome',
        component: RomeHome,
        meta: {
            whiteList: true
        }
    },
    {
        path: '/not-found-exception',
        name: 'NotFoundException',
        component: CmNotFoundException,
        meta: {
            whiteList: true
        }
    },
    {
        path: '/:catchAll(.*)', redirect: '/not-found-exception'
    }
];

export default {
    history: createWebHashHistory(process.env.BASE_URL),
    routes
};
