import { Attachment, request } from 'cmt-vue-lib';

/**
 * 代码生成管理
 * <p>
 * description 代码生成管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 12:21:02
 * @copyright (C) 2022 - 辰鸣科技
 */
export class CodeGenerateController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'CodeGenerateController.svc/';
    }

    // region 自动生成代码

    /**
     * 生成代码
     *
     * @param token {string} 用户令牌
     * @param modelDefineId {string} 模型Id
     * @param templateId {string} 模板Id
     */
    public generateCode(token: string, modelDefineId: string, templateId: string): Promise<string> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('modelDefineId', modelDefineId?.toString() || '');
        formData.append('templateId', templateId?.toString() || '');
        return request.post<string>(this.baseurl + 'generateCode', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 生成全部代码
     *
     * @param token {string} 用户令牌
     * @param modelDefineId {string} 模型Id
     */
    public generateAllCode(token: string, modelDefineId: string): Promise<Attachment> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('modelDefineId', modelDefineId?.toString() || '');
        return request.post<Attachment>(this.baseurl + 'generateAllCode', formData, { responseType: 'blob' }).then(res => {
            return new Attachment(res);
        });

    }


    // endregion

}
