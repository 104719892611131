
import { CmBaseComponent } from 'cmt-vue-lib';
import { defineComponent } from 'vue';
import { ProjectStructureEnum } from '@/enums/project-structure.enum';
import { ProjectStructureMgeController } from '@/services/project-structure-mge.controller';
import { ProjectStructureList } from '@/domains/project-structure.domain';
import {
    FolderOpenOutlined,
    FolderOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
    name: 'ModalDatabase',
    extends: CmBaseComponent,
    props: ['project'],
    components: {
        FolderOpenOutlined,
        FolderOutlined,
    },
    data() {
        return {
            visible: false,
            projectStructureEnum: ProjectStructureEnum,
            projectStructureMgeController: new ProjectStructureMgeController(),
            projectStructureList: new ProjectStructureList(),
            selectedTreeKeys: []
        };
    },
    created() {
        this.getProjectStructureListByProjectCode();
    },
    methods: {
        arrayTransformToTree<T>(array: Array<T>) {
            const loop = (pid: string) => {
                const list = [];
                array.forEach(item => {
                    if (item['parentId'] === pid) {
                        item['children'] = loop(item['id']);
                        list.push(item);
                    }
                });
                return list;
            };
            return loop('');
        },
        getProjectStructureListByProjectCode() {
            this.cmLoadingState = true;
            this.projectStructureMgeController.getProjectStructureListByProjectCode(this.token, this.project.code).then((res: ProjectStructureList) => {
                if (res) {
                    const filterList = res.filter(item => item.type === ProjectStructureEnum[ProjectStructureEnum['Group']]);
                    this.projectStructureList.items = this.arrayTransformToTree(filterList);
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmLoadingState = false;
            });
        },
        handleSelectTreeNode(keys) {
            this.selectedTreeKeys = keys;
        }
    }
});

