
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: "TView",
  props: {
    title: {
      type: String as PropType<string>,
      default: 't-view'
    }
  }
});
