import {defineComponent} from 'vue';
import {FormOutlined, SaveOutlined} from '@ant-design/icons-vue';
import {
    CmBaseComponent,
    DictValueList,
    DictTypeEnum,
    SysDictController
} from 'cmt-vue-lib';
import {ProjectMgeController} from '@/services/project-mge.controller';
import {CodeTemplateGroupController} from '@/services/code-template-group.controller';
import {Project} from '@/domains/project.domain';
import {CodeTemplateGroupList} from '@/domains/code-template-group.domain';
import { ProjectTeamController } from '@/services/project-team.controller';
import { ProjectTeamList } from '@/domains/project-team.domain';
import { TeamMemberList } from '@/domains/team-member.domain';

export default defineComponent({
    name: 'ProjectDetailIndex',
    title: '项目详情',
    components: {
        FormOutlined,
        SaveOutlined,
    },
    extends: CmBaseComponent,
    props: ['propProject'],
    setup() {
        const rules = {
            name: [{required: true, message: '请输入项目名称!', trigger: ['change', 'blur']}],
            teamId: [{required: true , message: '请选择项目团队!', trigger: ['change', 'blur']}],
            language: [{required: true, message: '请选择开发语言!', trigger: ['change', 'blur']}],
            databaseType: [{required: true, message: '请选择数据库类型!', trigger: ['change', 'blur']}],
            codeTemplateGroupId: [{required: true, message: '请选择数据库类型!', trigger: ['change', 'blur']}],
            description: [{required: false, message: '请输入项目说明!', trigger: ['change', 'blur']}],
        };
        return {rules};
    },
    data() {
        return {
            projectMgeController: new ProjectMgeController(),
            codeTemplateGroupController: new CodeTemplateGroupController(),
            sysDictController: new SysDictController(),
            project: new Project(), // 这是表单
            languageDictList: new DictValueList(), // 字典 - 开发语言
            databaseTypeDictList: new DictValueList(), // 字典 - 数据库类型
            codeTemplateGroupList: new CodeTemplateGroupList(),
            teamController: new ProjectTeamController(),
            projectTeams: new ProjectTeamList(),
        };
    },
    created() {
        this.getProjectTeamList();
        this.getProject();
        // 获取开发语言字典
        this.getDictListByLanguageType();
        // 获取数据类型字典
        this.getDictListByDbType();
    },
    methods: {
        getProjectTeamList() {
            this.teamController.getProjectTeamList(this.token, null, 0, -1).then(res => {
                if (res) {
                    this.projectTeams = res;
                }
            });
        },
        getDictListByLanguageType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DEV_LANGUAGE_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.languageDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        getDictListByDbType() {
            this.sysDictController.getDictValueList(this.token, DictTypeEnum.DB_TYPE, '', 0, -1).then((res: DictValueList) => {
                if (res) {
                    this.databaseTypeDictList = res;
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            });
        },
        filterTextFormatEmpty(text: string | Date): string {
            return text?.toString() || '-';
        },
        getProject() {
            this.cmLoadingState = true;
            this.projectMgeController.getProject(this.token, this.propProject.code).then(async (res: Project) => {
                if (res) {
                    this.project = res;
                    // 获取模板库
                    const codeTemplateGroupList = await this.codeTemplateGroupController.getCodeTemplateGroupList(this.token, this.cmKeyWord, this.project.language, 0, -1);
                    if (codeTemplateGroupList) {
                        this.codeTemplateGroupList = codeTemplateGroupList;
                    }
                }
            }).catch(err => {
                this.cmMsgSvr.error(err.message);
            }).finally(() => {
                this.cmLoadingState = false;
            });
        },
        onSaveProjectSubmit() {
            this.$refs.newProjectRef.validate().then(() => {
                this.cmModalSvr.confirm({
                    iconType: 'warning',
                    title: '操作提示',
                    content: '您确定要保存编辑后的数据吗？',
                    onOk: () => {
                        return new Promise<boolean>((resolve) => {
                            this.projectMgeController.modifyProject(
                                this.token,
                                this.project.code,
                                this.project.name,
                                this.project.description,
                                this.project.language,
                                this.project.databaseType,
                                this.project.codeTemplateGroupId).then(() => {
                                this.cmMsgSvr.success('修改项目信息成功');
                                this.getProject();
                                resolve(true);
                            }).catch(err => {
                                this.cmMsgSvr.error(err.message);
                                resolve(false);
                            });
                        });
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        onSelectLanguage(language: string) {
            // 切换语言选项 则 模板库变化
            this.project.codeTemplateGroupId = undefined;
            this.codeTemplateGroupList = new CodeTemplateGroupList();
            this.getCodeTemplateGroupList(language);
        },
    }
});
