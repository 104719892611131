import {BaseEntity} from 'cmt-vue-lib';

export class Property extends BaseEntity {
    id: string;
    name: string;
    value: string;

    constructor(options: {
        id?: string;
        name?: string;
        value?: string;
    } = {}) {
        super();
        this.id = options.id || new Date().getTime().toString();
        this.name = options.name || undefined;
        this.value = options.value || undefined;
    }
}
