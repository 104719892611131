import {CodeTemplateList} from '@/domains/code-template.domain';
import {BaseDataEntity} from 'cmt-vue-lib';
import {DataList} from 'cmt-vue-lib';

/**
 * 代码生成模板库
 *
 */
export class CodeTemplateGroup extends BaseDataEntity {
    id: string; // id
    name: string; // 模板库名称
    version: string; // 模板库版本
    publishDate: Date; // 模板库发布日期
    status: boolean; // 模板库状态
    language: string; // 模板语言
    codeTemplates: CodeTemplateList; // 代码生成模板列表
    constructor(options: any = {}) {
        super();
        this.id = options?.id || '';
        this.name = options?.name || '';
        this.version = options?.version || '';
        this.publishDate = options?.publishDate || null;
        this.status = options?.status || false;
        this.language = options?.language || '' || undefined;
        this.codeTemplates = new CodeTemplateList(options?.codeTemplates || {});
    }
}

export class CodeTemplateGroupList extends DataList<CodeTemplateGroup> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new CodeTemplateGroup(item));
            }
        }
    }
}
