import {DataList, BaseDataEntity} from 'cmt-vue-lib';

/**
 * 项目结构
 *
 */
export class ProjectStructure extends BaseDataEntity {
    id: string; // 结构Id
    parentId: string; // 父结构Id
    projectCode: string; // 项目编号
    name: string; // 结构名称
    type: string; // 结构类型（分组、对象、接口）
    modelDefineId: string; // 模型Id
    description: string; // 结构说明
    index: number; // 索引
    children: ProjectStructureList; // 子结构列表
    constructor(options: any = {}) {
        super(options);
        this.id = options?.id || '';
        this.parentId = options?.parentId || '';
        this.projectCode = options?.projectCode || '';
        this.name = options?.name || '';
        this.type = options?.type || '';
        this.modelDefineId = options?.modelDefineId || '';
        this.description = options?.description || '';
        this.index = options?.index || 0;
        this.children = options?.children ? new ProjectStructureList(options.children) : null;
    }
}


export class ProjectStructureList extends DataList<ProjectStructure> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ProjectStructure(item));
            }
        }
    }
}
