
import {defineComponent, ref, inject, markRaw, toRaw} from 'vue';
import type {TableColumnsType} from 'ant-design-vue';
import {Property} from './property.domain';
import {msgSvr, Utils} from 'cmt-vue-lib';

export default defineComponent({
  name: "ElementProperties",
  props: ['activeElement'],
  setup() {
    const size = inject('size', 'small');
    const prefix = inject('prefix', 'camunda');
    const designerInstance = inject('designerInstance', null);
    const modeling = inject('modeling', null);
    const moddle = inject('moddle', null);

    const columns = ref<TableColumnsType>([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        customRender: ({text, record, index}) => `${index + 1}`,
      },
      {
        key: 'name',
        dataIndex: 'name',
        align: 'center',
        title: '属性名',
        ellipsis: true,
        width: 70,
      },
      {
        key: 'value',
        dataIndex: 'value',
        align: 'center',
        title: '属性值',
        ellipsis: true,
        width: 70,
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
      },
    ]);
    const rules = {
      name: [{required: true, message: '请输入属性名!', trigger: ['change', 'blur']}],
      value: [{required: true, message: '请输入属性值!', trigger: ['change', 'blur']}],
    };

    return {
      size,
      prefix,
      designerInstance,
      modeling,
      moddle,
      columns,
      rules,
    };
  },
  data() {
    return {
      propertyModalVisible: false,
      propertyEditState: false,
      property: new Property(),

      otherExtensionList: [], // 其它属性（不包括自己定义扩展属性）
      elementPropertyList: [], // 自定义扩展属性 - 存在引用
      propertyList: [], // 自定义扩展属性 - 只显示

    };
  },
  watch: {
    'activeElement.id': {
      handler(val: string) {
        val && val.length && this.resetAttributesList();
      },
      immediate: true
    }
  },
  methods: {
    resetAttributesList() {
      this.otherExtensionList = [];
      const elementProperties =
          this.activeElement.businessObject?.extensionElements?.values?.filter((ex: any) => {
            if (ex.$type !== `${this.prefix}:Properties`) {
              this.otherExtensionList.push(ex);
            }
            return ex.$type === `${this.prefix}:Properties`;
          }) ?? [];

      this.elementPropertyList = markRaw(elementProperties.reduce((pre, current) => pre.concat(current.values), []));
      this.propertyList = Utils.cloneByJson(this.elementPropertyList);
    },
    onOpenNewOrEditModel(item?: any) {
      if (!item) {
        this.propertyEditState = false;
        this.property = new Property();
      } else {
        this.propertyEditState = true;
        this.property = new Property(item);
      }
      this.propertyModalVisible = true;
    },
    async onNewOrEditPropertySubmit() {
      if (await Utils.formValidate(this.$refs.propertyForm)) {
        if (!this.propertyEditState) {
          if (this.propertyList.some(item => item.name === this.property.name)) {
            return msgSvr.error('属性名不能重复');
          }
          // 新建属性字段
          const newPropertyObject = this.moddle.create(`${this.prefix}:Property`, this.property);
          const propertiesObject = this.moddle.create(`${this.prefix}:Properties`, {
            values: this.elementPropertyList.concat([newPropertyObject])
          });
          this.updateElementExtensions(propertiesObject);

        } else {
          const index = this.propertyList.findIndex(item => item.id === this.property.id);
          if (index >= 0) {
            // 更新扩展属性
            this.modeling.updateModdleProperties(markRaw(this.activeElement), this.elementPropertyList[index], this.property);
          }
        }
        this.propertyModalVisible = false;
        this.resetAttributesList();
      }
    },
    onDeletePropertySubmit(item: any) {
      const index = this.propertyList.findIndex(i => i.name === item.name);
      if (index >= 0) {
        this.propertyList.splice(index, 1);
        this.elementPropertyList.splice(index, 1);
        const propertiesObject = this.moddle.create(`${this.prefix}:Properties`, {
          values: this.elementPropertyList
        });
        this.updateElementExtensions(propertiesObject);
        this.resetAttributesList();
      }
    },
    updateElementExtensions(propertiesObject: any) {
      // todo... 这里values可能存在问题 等待修复
      const values = this.otherExtensionList.concat([propertiesObject]);
      const extensions = this.moddle.create("bpmn:ExtensionElements", {values});
      this.modeling.updateProperties(markRaw(this.activeElement), {
        extensionElements: values.length > 0 ? extensions : null
      });
    }
  }
});
