import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderOpenOutlined = _resolveComponent("FolderOpenOutlined")!
  const _component_FolderOutlined = _resolveComponent("FolderOutlined")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_cm_empty = _resolveComponent("cm-empty")!
  const _component_cm_card_body = _resolveComponent("cm-card-body")!
  const _component_cm_card = _resolveComponent("cm-card")!
  const _directive_cmLoading = _resolveDirective("cmLoading")!

  return _withDirectives((_openBlock(), _createBlock(_component_cm_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_cm_card_body, null, {
        default: _withCtx(() => [
          (_ctx.projectStructureList.items.length > 0)
            ? (_openBlock(), _createBlock(_component_a_tree, {
                key: 0,
                "tree-data": (_ctx.projectStructureList.items as any),
                showLine: { showLeafIcon: false },
                blockNode: "",
                defaultExpandAll: "",
                "auto-expand-parent": true,
                draggable: "",
                showIcon: "",
                virtual: false,
                fieldNames: { children: 'children', title: 'name', key: 'id' },
                "selected-keys": _ctx.selectedTreeKeys,
                onSelect: _ctx.handleSelectTreeNode
              }, {
                switcherIcon: _withCtx(({ expanded }) => [
                  expanded
                    ? (_openBlock(), _createBlock(_component_FolderOpenOutlined, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_FolderOutlined, { key: 1 }))
                ]),
                _: 1
              }, 8, ["tree-data", "selected-keys", "onSelect"]))
            : (_openBlock(), _createBlock(_component_cm_empty, { key: 1 }))
        ]),
        _: 1
      })
    ]),
    _: 1
  })), [
    [_directive_cmLoading, _ctx.cmLoadingState]
  ])
}