
import {defineComponent, markRaw} from 'vue';
import DesignerTools from '../penal/DesignerTools.vue';
import DesignerPanel from '../penal/DesignerPanel.vue';

import DefaultEmptyXML from "./config/defaultEmpty";
import customTranslate from "./config/translate/customTranslate";
import translationsCN from "./config/translate/zh";

import BpmnModeler, {Modeler} from 'bpmn-js/lib/Modeler';
import TokenSimulationModule from 'bpmn-js-token-simulation';
import minimapModule from 'diagram-js-minimap';
import lintModule from 'bpmn-js-bpmnlint';
import * as bpmnLintConfig from './config/packed-config';


// import {
//   BpmnPropertiesPanelModule,
//   BpmnPropertiesProviderModule,
//   CamundaPlatformPropertiesProviderModule,
// } from 'bpmn-js-properties-panel';

import CamundaBpmnModdle from 'camunda-bpmn-moddle/resources/camunda.json';


export default defineComponent({
  name: "ProcessDesigner",
  components: {
    DesignerTools,
    DesignerPanel
  },
  props: {
    processXml: String, // xml 字符串
    processId: String, // 流程 key 标识
    processName: String, // 流程 name 名字
  },
  data() {
    return {
      bpmnModeler: null as Modeler,
      customTranslateModule: {
        translate: ['value', customTranslate(translationsCN)]
      }
    };
  },
  mounted() {
    this.initBpmnModeler();
    this.$nextTick(() => {
      this.createNewDiagram(this.processXml);
    });
  },
  beforeUnmount() {
    if (this.bpmnModeler) {
      this.bpmnModeler.destroy();
    }
    this.bpmnModeler = null;
  },
  methods: {
    initBpmnModeler() {
      this.bpmnModeler = markRaw(new BpmnModeler({
        container: '.process-designer__canvas',
        keyboard: {bindTo: document},
        // propertiesPanel: {
        //   parent: '.process-designer__plane'
        // },
        linting: {
          bpmnlint: bpmnLintConfig
        },
        additionalModules: [
          this.customTranslateModule,
          TokenSimulationModule,
          minimapModule,
          lintModule,

          // BpmnPropertiesPanelModule,
          // BpmnPropertiesProviderModule,
          // CamundaPlatformPropertiesProviderModule,
        ],
        moddleExtensions: {
          camunda: CamundaBpmnModdle,
        }
      }));
    },
    createNewDiagram(xml?: string) {
      const timeId = new Date().getTime();
      // id不能以数字开头，否则导入xml报错。
      const newId = this.processId || `Process_${timeId}`;
      const newName = this.processName || `业务流程_${timeId}`;
      const xmlString = xml || DefaultEmptyXML(newId, newName);

      this.bpmnModeler.importXML(xmlString).then(({warnings}) => {
        const canvas = this.bpmnModeler.get('canvas');
        canvas.zoom("fit-viewport", "auto");
        if (warnings && warnings.length) {
          warnings.forEach(warn => console.warn(warn));
        }
      }).catch(err => {
        // console.error(err);
        this.$emit('error', err);
      });
    },
  }
});
