import {BaseEntity, DataList} from 'cmt-vue-lib';
import {TeamMemberList} from '@/domains/team-member.domain';
import {ProjectList} from '@/domains/project.domain';

/**
 *
 * 项目团队
 * <p>
 * description 项目团队模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:40:24
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProjectTeam extends BaseEntity {

    /**
     * 团队Id
     */
    id: string;
    /**
     * 团队名称
     */
    name: string;
    /**
     * 团队描述
     */
    description: string;
    /**
     * 团队图标
     */
    avatar: string;
    /**
     * 是否私有项目
     */
    privateProject: boolean;
    /**
     * 管理员Id
     */
    adminId: string;
    /**
     * 团队成员
     */
    teamMembers: TeamMemberList;
    /**
     * 项目列表
     */
    projects: ProjectList;
    /**
     * 备注
     */
    remark: string;

    constructor(options: any = {}) {
        super();
        this.id = options?.id || '';
        this.name = options?.name || '';
        this.description = options?.description || '';
        this.avatar = options?.avatar || '';
        this.privateProject = !!options?.privateProject;
        this.adminId = options?.adminId || '';
        this.teamMembers = new TeamMemberList(options.teamMembers || {});
        this.projects = new ProjectList(options.projects || {});
        this.remark = options?.remark || '';
    }
}

/**
 *
 * 项目团队列表
 * <p>
 * description 项目团队模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:40:24
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProjectTeamList extends DataList<ProjectTeam> {
    constructor(options: any = {}) {
        super(options);
        if (options.items) {
            for (const item of options.items) {
                this.append(new ProjectTeam(item));
            }
        }
    }
}

