import {request} from 'cmt-vue-lib';
import {ProcessTemplate, ProcessTemplateList} from '../domains/process-template.domain';

/**
 * 流程模板控制器
 * <p>
 * description 流程模板控制器定义
 *
 * @author 超管理员(admin)
 * @date 2022-05-25 11:23:08
 * @copyright (C) 2022 - 辰鸣科技
 */
export class ProcessTemplateController {
    private baseurl: string;

    constructor() {
        this.baseurl = 'ProcessTemplateController.svc/';
    }

    // region 自动生成代码

    /**
     * 添加流程模板
     *
     * @param token {string} 用户令牌
     * @param template {ProcessTemplate} 模板
     */
    public addProcessTemplate(token: string ,template: ProcessTemplate): Promise<ProcessTemplate>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('template', template?.toString() || '');
        return request.post<ProcessTemplate>(this.baseurl + 'addProcessTemplate', formData
        ).then(res => {
            return !!res ? new ProcessTemplate(res) : null;
        });
    }

    /**
     * 修改流程模板
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     * @param template {ProcessTemplate} 模板
     */
    public modifyProcessTemplate(token: string ,id: string ,template: ProcessTemplate): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('template', template?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyProcessTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 发布流程模板
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     */
    public publishProcessTemplate(token: string ,id: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<void>(this.baseurl + 'publishProcessTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 修改BPMN模型
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     * @param bpmnModelXml {string} BPMN模型
     */
    public modifyBpmnModel(token: string ,id: string ,bpmnModelXml: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('bpmnModelXml', bpmnModelXml?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyBpmnModel', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取流程模型
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     */
    public getProcessTemplate(token: string ,id: string): Promise<ProcessTemplate>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<ProcessTemplate>(this.baseurl + 'getProcessTemplate', formData
        ).then(res => {
            return !!res ? new ProcessTemplate(res) : null;
        });
    }

    /**
     * 删除流程模型
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     */
    public deleteProcessTemplate(token: string ,id: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteProcessTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 设置流程模板状态
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     * @param status {boolean} 状态
     */
    public setProcessTemplateStatus(token: string ,id: string ,status: boolean): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('status', status?.toString() || '');
        return request.post<void>(this.baseurl + 'setProcessTemplateStatus', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取流程模板列表
     *
     * @param token {string} 用户令牌
     * @param keyword {string} 关键字
     * @param status {boolean} 状态
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getProcessTemplateList(token: string ,keyword: string ,status: boolean ,startIndex: number ,maxSize: number): Promise<ProcessTemplateList>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('keyword', keyword?.toString() || '');
        formData.append('status', status?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<ProcessTemplateList>(this.baseurl + 'getProcessTemplateList', formData
        ).then(res => {
            return !!res ? new ProcessTemplateList(res) : null;
        });
    }

    // endregion

}
