import {request} from 'cmt-vue-lib';
import {TableInfoList} from '@/domains/table-info.domain';

/**
 * 数据库管理
 * <p>
 * description 数据库管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 13:51:18
 * @copyright (C) 2022 - 辰鸣科技
 */
export class DatabaseController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'DatabaseController.svc/';
    }

    // region 自动生成代码

    /**
     * 测试连接是否成功
     *
     * @param token {string} 用户令牌
     * @param repositoryId {string} 数据库仓库ID
     */
    public testConnection(token: string, repositoryId: string): Promise<boolean> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('repositoryId', repositoryId?.toString() || '');
        return request.post<boolean>(this.baseurl + 'testConnection', formData).then(res => {
            return !!res;
        });
    }

    /**
     * 获取数据库表名
     *
     * @param token {string} 用户令牌
     * @param repositoryId {string} 数据库仓库ID
     * @param keyWord{string} 关键字
     */
    public getTableInfoList(token: string, repositoryId: string, keyWord: string): Promise<TableInfoList> {
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('repositoryId', repositoryId?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        return request.post<TableInfoList>(this.baseurl + 'getTableInfoList', formData).then(res => {
            return !!res ? new TableInfoList(res) : null;
        });
    }

    // endregion

}
