import {request} from 'cmt-vue-lib';
import {CodeTemplate, CodeTemplateList} from '@/domains/code-template.domain';

/**
 * 代码模板生成管理
 * <p>
 * description 代码模板生成管理
 *
 * @author 超管理员(admin)
 * @date 2022-05-11 13:51:18
 * @copyright (C) 2022 - 辰鸣科技
 */
export class CodeTemplateController {
    public baseurl: string;

    constructor() {
        this.baseurl = 'CodeTemplateController.svc/';
    }

    // region 自动生成代码

    /**
     * 设置代码模板状态
     *
     * @param token {string} 用户令牌
     * @param id {string} 模板Id
     * @param status {boolean} 状态
     */
    public setCodeTemplateStatus(token: string ,id: string ,status: boolean): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('status', status?.toString() || '');
        return request.post<void>(this.baseurl + 'setCodeTemplateStatus', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 设置模板附件
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param templateContent {any} 模板内容
     */
    public setTemplateAttachment(token: string ,id: string ,templateContent: any): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('templateContent', templateContent);
        return request.post<void>(this.baseurl + 'setTemplateAttachment', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 修改代码模板
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param name {string} Name
     * @param templateGroupId {string} 模板组
     * @param templateType {string} 模板类型
     * @param modelType {number} 模型类型
     * @param prefix {string} 前缀
     * @param suffix {string} 后缀
     * @param extension {string} 扩展
     * @param version {string} 版本
     * @param templateContent {any} 模板内容
     * @param remark {string} 备注
     */
    public modifyCodeTemplate(token: string ,id: string ,name: string ,templateGroupId: string ,templateType: string ,modelType: number ,prefix: string ,suffix: string ,extension: string ,version: string ,templateContent: any ,remark: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('templateGroupId', templateGroupId?.toString() || '');
        formData.append('templateType', templateType?.toString() || '');
        formData.append('modelType', modelType?.toString() || '');
        formData.append('prefix', prefix?.toString() || '');
        formData.append('suffix', suffix?.toString() || '');
        formData.append('extension', extension?.toString() || '');
        formData.append('version', version?.toString() || '');
        formData.append('templateContent', templateContent);
        formData.append('remark', remark?.toString() || '');
        return request.post<void>(this.baseurl + 'modifyCodeTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 设置代码模板版本
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     * @param version {string} 版本号
     */
    public setCodeTemplateVersion(token: string ,id: string ,version: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        formData.append('version', version?.toString() || '');
        return request.post<void>(this.baseurl + 'setCodeTemplateVersion', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取代码模板
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     */
    public getCodeTemplate(token: string ,id: string): Promise<CodeTemplate>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<CodeTemplate>(this.baseurl + 'getCodeTemplate', formData
        ).then(res => {
            return !!res ? new CodeTemplate(res) : null;
        });
    }

    /**
     * 添加代码模板
     *
     * @param token {string} 用户令牌
     * @param name {string} Name
     * @param templateGroupId {string} 模板组
     * @param templateType {string} 模板类型
     * @param modelType {number} 模型类型
     * @param prefix {string} 前缀
     * @param suffix {string} 后缀
     * @param extension {string} 扩展
     * @param version {string} 版本
     * @param templateContent {any} 模板内容
     */
    public addCodeTemplate(token: string ,name: string ,templateGroupId: string ,templateType: string ,modelType: number ,prefix: string ,suffix: string ,extension: string ,version: string ,templateContent: any): Promise<string>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('name', name?.toString() || '');
        formData.append('templateGroupId', templateGroupId?.toString() || '');
        formData.append('templateType', templateType?.toString() || '');
        formData.append('modelType', modelType?.toString() || '');
        formData.append('prefix', prefix?.toString() || '');
        formData.append('suffix', suffix?.toString() || '');
        formData.append('extension', extension?.toString() || '');
        formData.append('version', version?.toString() || '');
        formData.append('templateContent', templateContent);
        return request.post<string>(this.baseurl + 'addCodeTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 删除代码模板
     *
     * @param token {string} 用户令牌
     * @param id {string} Id
     */
    public deleteCodeTemplate(token: string ,id: string): Promise<void>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('id', id?.toString() || '');
        return request.post<void>(this.baseurl + 'deleteCodeTemplate', formData
        ).then(res => {
            return res;
        });
    }

    /**
     * 获取代码模板列表
     *
     * @param token {string} 用户令牌
     * @param keyWord {string} 关键字
     * @param templateGroupId {string} 模板组
     * @param templateType {string} 模板类型
     * @param startIndex {number} 开始索引
     * @param maxSize {number} 最大返回数
     */
    public getCodeTemplateList(token: string ,keyWord: string ,templateGroupId: string ,templateType: string ,startIndex: number ,maxSize: number): Promise<CodeTemplateList>{
        const formData = new FormData();
        formData.append('token', token?.toString() || '');
        formData.append('keyWord', keyWord?.toString() || '');
        formData.append('templateGroupId', templateGroupId?.toString() || '');
        formData.append('templateType', templateType?.toString() || '');
        formData.append('startIndex', startIndex?.toString() || '');
        formData.append('maxSize', maxSize?.toString() || '');
        return request.post<CodeTemplateList>(this.baseurl + 'getCodeTemplateList', formData
        ).then(res => {
            return !!res ? new CodeTemplateList(res) : null;
        });
    }

    // endregion

}
