
import {defineComponent} from 'vue';
import {
  CmBaseComponent,
} from 'cmt-vue-lib';
import ReportsViewer from "@/components/reports-designer/src/components/ReportsViewer.vue";

export default defineComponent({
  name: 'RoleHome2',
  extends: CmBaseComponent,
  components: {ReportsViewer}
});
