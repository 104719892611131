import RoleHome from '@/views/RoleHome.vue';
import RoleHome2 from '@/views/RoleHome2.vue';
import ProjectManage from '@/views/project/project-manage/project-manage.component.vue';
import ProjectDetail from '@/views/project/project-detail/project-detail.component.vue';
import ProjectDetailIndex
    from '@/views/project/project-detail/project-detail-index/project-detail-index.component.vue';
import ProjectDetailStructure
    from '@/views/project/project-detail/project-detail-structure/project-detail-structure.component.vue';
import ProjectGenerationCode
    from '@/views/project/project-detail/project-generation-code/project-generation-code.component.vue';
import ProjectTemplateLibrary from '@/views/project/project-template-library/project-template-library.component.vue';

import DesignManage from '@/views/system/system-manage/design-manage/design-manage.component.vue';

import ViewChild from "@/views/dynamic-views/view-child.vue";
import ViewBase from "@/views/dynamic-views/view-base.vue";

import TCore from "@/views/dynamic-views/test/t-core.vue";
import TContainer from "@/views/dynamic-views/test/t-container.vue";
import TView from "@/views/dynamic-views/test/t-view.vue";

export default [
    RoleHome,
    RoleHome2,
    ProjectManage,
    ProjectDetail,
    ProjectDetailIndex,
    ProjectDetailStructure,
    ProjectGenerationCode,
    ProjectTemplateLibrary,

    DesignManage,

    ViewChild,
    ViewBase,

    TCore,
    TContainer,
    TView
];
