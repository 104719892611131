import {BaseEntity, DataList} from 'cmt-vue-lib';

/**
 *
 * 数据库表
 * <p>
 * description 数据库表模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:39:03
 * @copyright (C) 2022 - 辰鸣科技
 */
export class TableInfo extends BaseEntity {

    /**
     * 表名称
     */
    name: string;
    /**
     * 表说明
     */
    description: string;

    constructor(options: {
        name?: string;
        description?: string;
    } = {}) {
        super();
        this.name = options?.name || '';
        this.description = options?.description || '';
    }
}

/**
 *
 * 数据库表列表
 * <p>
 * description 数据库表模型定义
 *
 * @author 向朗(xlang)
 * @date 2023-09-08 15:39:03
 * @copyright (C) 2022 - 辰鸣科技
 */
export class TableInfoList extends DataList<TableInfo> {
    constructor(options?: any) {
        super();
        for (const entity of Array.isArray(options) ? options : options?.items || []) {
            this.append(new TableInfo(entity));
        }
    }
}

